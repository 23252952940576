    <!-- **************************** -->
<!-- main banner css -->
<!-- **************************** -->
<ngx-spinner></ngx-spinner>
<div id="demo" class="carousel slide hero-slider home-slider" data-ride="carousel"   style="transition-delay: 250ms;
transition-property: margin-right;">
        <div class="container min-h-400">
            <div class="bg-slider min-h-400">
            <div class="carousel-inner">
                <ngx-slick class="carousel" #slickModal="slick-modal" [config]="slideConfig">
                    <div ngxSlickItem *ngFor="let data of sliderImages; let first = first;" [ngClass]="{'first_slide': first, 'first_slide_cursor': first}" class="slide" (click)="itemClickOnNgxClick(data)">
                        <img [src]="imageBasePath + data.image" alt="" width="100%">
                        <div class="carousel-caption d-none d-md-block text-left">
                            <h3>{{data?.title}}</h3>
                        </div>
                    </div>
                </ngx-slick>
                <!-- <carousel [noPause]="false" [isAnimated]="true" [interval]="cycleInterval">
                    <slide *ngFor="let data of sliderImages; let i=index">
                        <img [src]="imageBasePath + data.image" alt="first slide" style="display: block; width: 100%;">
                        <div class="carousel-caption d-none d-md-block text-left">
                            <h3>{{data?.title}}</h3>
                        </div>
                    </slide>
                </carousel> -->
            </div>
        </div>
        </div>
    </div>
                            <!-- <p><strong><span class="roboto-light">By<strong>:</strong></span> {{data?.author}}</strong></p> -->


<!-- **************************** -->
<!-- main banner css end-->
<!-- **************************** -->


<!-- **************************** -->
<!-- about us-->
<!-- **************************** -->


<section class="about-us home-abtus pt-5 pb-3">
    <div class="container hide-on-init" animateOnScroll animationName="animated fadeIn">
        <div class="row abt-content" >
            <div class="col-md-12 text-center">
                <div class="wrap">
                <p class="font20"><strong>Social App Hub fosters the use of mobile technology for Social Good.</strong></p>
                <p class="mb-0">It is <strong>India's first</strong> crowdsourcing platform aimed at curating, strengthening & promoting social sector related mobile solutions from critical sectors of health, education, governance, agriculture, disability and safety. The platform also offers a plethora of knowledge through its case studies, articles & interviews to foster technology enabled social innovation in the development sector.</p>
            </div>
        </div>
        </div>
    </div>
</section>

<!-- **************************** -->
<!-- about css end-->
<!-- **************************** -->

<!-- **************************** -->
<!-- about us-->
<!-- **************************** -->
<section class="featured-newsfeeds pt-4">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-12 newsfeeds hide-on-init" animateOnScroll animationName="animated fadeIn">
                <div class="col-wrap">
                    <h3>Highlights</h3>
                    <ul>
                        <li *ngFor="let data of newsHtml">
                            <a [attr.href]="data.link" target="_blank">{{data?.title}}</a>
                        </li>
                        <!--<li>
                             <a href="#">There are many variations of passages of Lorem Ipsum available, but the majority </a>
                         </li>
                         <li>
                             <a href="#">There are many variations of passages of Lorem Ipsum available, but the majority </a>
                         </li>
                         <li>
                             <a href="#">There are many variations of passages of Lorem Ipsum available, but the majority </a>
                         </li>
                         <li>
                             <a href="#">There are many variations of passages of Lorem Ipsum available, but the majority </a>
                         </li>
                         <li>
                             <a href="#">There are many variations of passages of Lorem Ipsum available, but the majority </a>
                         </li>
                         <li>
                             <a href="#">There are many variations of passages of Lorem Ipsum available, but the majority </a>
                         </li>
                         <li>
                             <a href="#">There are many variations of passages of Lorem Ipsum available, but the majority </a>
                         </li> -->
                    </ul>
                </div>
            </div>
            <!-- newsfeeds end -->

            <div class="col-md-8 col12 featapp-col">
                <div class="col-wrap section-title">

                        <h2 class="roboto-light orange-line relative" ><span>Featured Apps</span></h2>


                   <div class="slider-handle"> <a href="javascript:;" (click)="prevPage()"><i class="fa fa-angle-left"></i></a>  <a href="javascript:;" (click)="nextPage()"><i class="fa fa-angle-right"></i></a></div>
                    <div class="featured-app row justify-content-center">
                        <div class="col-md-4 app-col fct-1" *ngFor="let app of approvedApplications" (click)="detailOfApp(app)">
                            <div class="wrap h-100">
                                <div class="f-app1">
                                    <img src="{{imageBasePath}}{{app?.app_logo}}" >
                                </div>
                                <p>{{app?.name}}</p>
                                <div class="rating">
                                    <rating [(ngModel)]="app.rating" [readonly]="true"></rating>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- newsfeeds end -->

        </div>
    </div>
</section>
<!-- **************************** -->
<!-- features css end-->
<!-- **************************** -->

<section class="articles">
    <div class="container---">
        <div class="row">
            <div class="col-md-4 col-12 art-left hide-on-init" animateOnScroll animationName="animated fadeIn">
                <div class="wrap">
                    <h2>Articles</h2>
                    <a routerLink="/knowledge-section/articles" class="btn btn-primary d-none d-lg-block articles-btn-desk">View All</a>
                </div>
            </div>
            <div class="col-md-8 col12 art-right hide-on-init" animateOnScroll animationName="animated fadeIn" *ngIf="articlesHtml">
                <div class="carousel art mb-0 carousel-inner">
                    <ngx-slick class="carousel" #slickModal="slick-modal" [config]="slideConfigArticles">
                        <div ngxSlickItem *ngFor="let data of articlesHtml" class="slide">
                            <a [routerLink]="['/knowledge-section/article/detail',data?._id]">
                                <div class="artslide-img">
                                <img [src]="imageBasePath + data.images[0]" alt="first slide"
                                    style="display: block; width: 100%;">
                                </div>
                                <div class="carousel-caption d-none d-md-block text-left">
                                    <p [innerHtml]="transformTitle(data?.title)"></p>
                                    <!-- <p [innerHtml]="transform((data.title.length > 50) ? (data.title | slice:0:45 | safeHtml) + '...' : data.title)"> -->
                                    <!-- </p> -->
                                </div>
                            </a>
                        </div>
                    </ngx-slick>
                </div>
                <a routerLink="/knowledge-section/articles" class="btn btn-primary mt-3 articles-btn-mob d-lg-none d-block ml-auto mr-auto width-x-100">View All</a>
            </div>
        </div>
    </div>
</section>

<!-- **************************** -->
<!-- article  end-->
<!-- **************************** -->


<!-- **************************** -->
<!-- interviews page start-->
<!-- **************************** -->
<section class="interviews relative overflow-visible home-interview" >
    <div class="container">
        <div class="row section-title max-width6">
            <div class="col-md-12">
                <h2 class="roboto-light orange-line relative roboto-light pb-0 mb-5 hide-on-init" animateOnScroll animationName="animated fadeIn"><span>Interviews</span></h2>
            </div>
        </div>

        <div class="row interviews-row initial-interview" *ngFor="let data of interviewsHtml; let i=index; let isOdd=odd">
            <ng-container *ngIf="isOdd; else evenBlock">
                <div class="col-md-6 col-4 intr-left hide-on-init" animateOnScroll animationName="animated fadeIn">
                    <div class="wrap">
                        <img [src]="imageBasePath + data.images[0]" alt="">
                    </div>
                </div><!-- intr-left end -->

                <div class="col-md-6 col-8 intr-right hide-on-init" animateOnScroll animationName="animated fadeIn">
                    <div class="wrap">
                            <h2>{{data?.title}}</h2>
                            <p class="interview-with"><span class="interview-with-span"><span class="text-grey">With:</span> {{data?.candidate_name}}</span> </p>
                            <a [routerLink]="['/knowledge-section/interview/detail',data?._id]" class="btn btn-primary pl-3 pr-3 rounded-pill">Read</a>
                    </div>
                </div>
            </ng-container>
            <ng-template #evenBlock>
                <div class="col-md-6 col-4 intr-left hide-on-init" animateOnScroll animationName="animated fadeIn">
                    <div class="wrap">
                        <img [src]="imageBasePath + data.images[0]" alt="">
                    </div>
                </div><!-- intr-left end -->

                <div class="col-md-6 col-8 intr-right hide-on-init" animateOnScroll animationName="animated fadeIn">
                    <div class="wrap">
                            <h2>{{data?.title}}</h2>
                            <p class="interview-with"><span class="interview-with-span"><span class="text-grey">With:</span> {{data?.candidate_name}}</span></p>
                            <a [routerLink]="['/knowledge-section/interview/detail',data?._id]" class="btn btn-primary pl-3 pr-3 rounded-pill">Read</a>
                    </div>
                </div>
            </ng-template>

            <!-- intr-right end -->

        </div>



        <a routerLink = "/knowledge-section/interview" class="show_hide btn btn-default expand-btn rounded-pill hide-on-init" animateOnScroll animationName="animated fadeIn">View All</a>
    </div>
</section>

<!-- **************************** -->
<!-- interviews page end-->
<!-- **************************** -->


<section class="casestudy">
    <div class="container  pb-280">
        <h2 class="roboto-light text-white mb-5 text-center hide-on-init" animateOnScroll animationName="animated fadeIn">Case Studies</h2>
        <div class="row study-row mt-110 pt-2">

            <div class="col-md-4 study-col hide-on-init" animateOnScroll animationName="animated fadeIn" *ngFor="let data of caseStudyHtml; let i=index">
                <div class="wrap h-100">
                    <div class="study-img"><img [src]="imageBasePath + data.images[0]" alt=""></div>
                    <h3>{{data.title}}</h3>
                    <div class="study-content">
                        <p class="text-left" [innerHtml]="transform((data.description.length > 100) ? (data.description | slice:0:100 | safeHtml) + '...' : data.description)"></p>
                        <a [routerLink]="['/knowledge-section/case-study/detail',data?._id]" class="btn btn-primary rounded-pill pr-4 pl-4">View Case Study</a>
                    </div>
                </div>
            </div>



        </div>
        <div class="row view-btn mt-5 text-center hide-on-init" animateOnScroll animationName="animated fadeIn">
            <div class="col-md-12"><a class="btn btn-default text-white border-white rounded-pill"routerLink = "/knowledge-section/case-study">View All</a>
            </div>
        </div>
    </div>
</section>



