import { environment } from '../../../environments/environment';

export class ApiRoutes {
    // api base url
    static apiV1: string = 'api/v1/';
    public static apiBaseUrl: string = environment.apiBaseUrl + ApiRoutes.apiV1;

    public static get login(): string { return this.apiBaseUrl+ 'user/login'; }
    public static get register(): string { return this.apiBaseUrl + 'user/register'; }
    public static get otpregister(): string { return this.apiBaseUrl + 'sendOTP'; }
    public static get registerpassword(): string { return this.apiBaseUrl + 'save_password'; }
    public static get profile(): string { return this.apiBaseUrl + 'profile'; }
    public static get updateProfile(): string { return this.apiBaseUrl + 'update_profile'; }
    public static get emailVerification(): string { return this.apiBaseUrl + 'player/activate/?token='; }
    public static get forgotPassword(): string { return this.apiBaseUrl + 'forgot_password'; }
    public static get changePassword(): string { return this.apiBaseUrl + 'reset_password'; }
    public static get resetPassword(): string { return this.apiBaseUrl + 'update_password'; }
    public static get setPassword(): string { return this.apiBaseUrl + 'set_password'; }
    public static get checkUserStatus(): string { return this.apiBaseUrl + 'social_auth'; }
    public static get checkResetToken(): string { return this.apiBaseUrl + 'check_reset_token'; }
    public static get verifyUser(): string { return this.apiBaseUrl + 'user/verification'; }
    public static get verifyOtp(): string { return this.apiBaseUrl+ 'verifyOTP'; }
    public static get resendOtp(): string { return this.apiBaseUrl+ 'resendOTP'; }

    //Informatory-pages
    public static get ourStory(): string { return this.apiBaseUrl + 'staticpage/our_story'; }
    public static get aboutUs(): string { return this.apiBaseUrl + 'staticpage/about_us'; }
    public static get privacyPolicy(): string { return this.apiBaseUrl + 'staticpage/privacy_policy'; }
    public static get staticPages(): string { return this.apiBaseUrl + 'staticpage/'; }
    public static get termsAndConditions(): string { return this.apiBaseUrl + 'staticpage/terms_conditions'; }
    public static get disclaimer(): string { return this.apiBaseUrl + 'staticpage/disclaimer'; }
    public static get caseStudySliderImages(): string { return this.apiBaseUrl + 'casestudy_slider'; }
    public static get caseStudy(): string { return this.apiBaseUrl + 'list_casestudy'; }
    public static get caseStudyDetails(): string { return this.apiBaseUrl + 'view_casestudy'; }
    public static get interviewSliderImages(): string { return this.apiBaseUrl + 'interview_slider'; }
    public static get interview(): string { return this.apiBaseUrl + 'list_interview'; }
    public static get interviewDetails(): string { return this.apiBaseUrl + 'view_interview'; }
    public static get similarInterviewDetails(): string { return this.apiBaseUrl + 'related_interview'; }
    public static get news(): string { return this.apiBaseUrl + 'list_news'; }
    public static get clicknews(): string { return this.apiBaseUrl + 'update_click'; }
    public static get articlesSliderImages(): string { return this.apiBaseUrl + 'articles_slider'; }
    public static get articles(): string { return this.apiBaseUrl + 'list_articles'; }
    public static get articleDetails(): string { return this.apiBaseUrl + 'view_article'; }
    public static get similarArticlesDetails(): string { return this.apiBaseUrl + 'related_articles'; }
    public static get ngoSliderImages(): string { return this.apiBaseUrl + 'ngo_slider'; }
    public static get ngo(): string { return this.apiBaseUrl + 'list_ngo'; }
    public static get homeSlider(): string { return this.apiBaseUrl + 'slider/list'; }
    public static get caseSimilarStudyDetails(): string { return this.apiBaseUrl + 'related_casestudy'; }

    //App URL
    public static get getCategories(): string { return this.apiBaseUrl + 'category_with_subcategories'; }
    public static get submitApp(): string { return this.apiBaseUrl + 'add_application'; }
    public static get myApps(): string { return this.apiBaseUrl + 'my_apps'; }

    public static get approvedApps(): string { return this.apiBaseUrl + 'featured_application'; }


}
