import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CategoriesService } from 'src/app/shared/services/header.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-newsletter-preview',
  templateUrl: './newsletter-preview.component.html',
  styleUrls: ['./newsletter-preview.component.scss']
})
export class NewsletterPreviewComponent implements OnInit {

  newsletter_id : any ;
  newsLetterPreview : any;
  imagePath = environment.imgUrl

  constructor(private activatedroute : ActivatedRoute,
    private service :CategoriesService,
		private toastr: ToastrService,
    ) {
    this.newsletter_id = this.activatedroute.snapshot.params.id;
  }



  getNewsLetterPreviewById(){
    this.service.getNewsLetterById(this.newsletter_id).subscribe(resp=>{
        if(resp['status']){
          this.newsLetterPreview = resp['payload']['newsLetter'];
          console.log(this.newsLetterPreview);
          
        }
    }, err => {
      if (err.error.message) {
        this.toastr.error(err.error.message, '');
      }
      else {
        this.toastr.error('Something went wrong. Please try again later', '');
      }
    })
  }

  ngOnInit(): void {
    this.getNewsLetterPreviewById();
  }

}
