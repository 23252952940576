import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule,TitleCasePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RegisterComponent } from './components/preAuthPages/register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AnimateInModule } from 'ngx-animate-in';
import {RatingModule} from "ngx-rating";
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { CountdownModule } from 'ngx-countdown';

import {
  SocialLoginModule,
  AuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angular5-social-login";
import { NgxSpinnerModule } from "ngx-spinner";

import { ForgotPasswordComponent } from './components/preAuthPages/forgot-password/forgot-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AuthInterceptor } from './authentication/services/auth.interceptor';
import { ChangePasswordComponent } from './components/preAuthPages/change-password/change-password.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { SocialLoginSetPasswordComponent } from './components/preAuthPages/social-login-set-password/social-login-set-password.component';
import { environment } from 'src/environments/environment';
import { NewsletterSubscriprionComponent } from './shared/components/newsletter-subscriprion/newsletter-subscriprion.component';
import { SubmitIdeaComponent } from './shared/components/submit-idea/submit-idea.component';
import { BsDropdownModule,BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { DiscoverAppComponent } from './components/discover-app/discover-app.component';
import { DiscoverAppDetailsComponent } from './components/discover-app-details/discover-app-details.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { SlickModule } from 'ngx-slick';
import { SearchPageComponent } from './shared/components/search-page/search-page.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule} from 'ngx-sharebuttons/icons'
import 'hammerjs';
import { VerificationComponent } from './shared/components/verification/verification.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { OtpScreenComponent } from './components/preAuthPages/otp-screen/otp-screen.component';

import { NgOtpInputModule } from  'ng-otp-input';
import { CounterDirective } from './components/preAuthPages/register/timer.directive';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { NewsletterPreviewComponent } from './components/newsletter-preview/newsletter-preview.component';

// Configs
export function getAuthServiceConfigs() {
  let config = new AuthServiceConfig(
      [
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider(<any>environment.facebookProviderId)
        },
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(environment.googleProviderId)
        },
      ]
  );
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    HeaderComponent,
    FooterComponent,
    SocialLoginSetPasswordComponent,
    NewsletterSubscriprionComponent,
    SubmitIdeaComponent,
    DiscoverAppComponent,
    DiscoverAppDetailsComponent,
    SearchPageComponent,
    VerificationComponent,
    OtpScreenComponent,
    CounterDirective,
    NewsletterComponent,
    NewsletterPreviewComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SocialLoginModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    SharedModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      maxOpened: 1,
      timeOut: 10000,
      progressBar:true,
      easing:'ease-in'
    }),
    AnimateInModule,
    NgxSpinnerModule,
    CKEditorModule,
    SlickModule.forRoot(),
    RatingModule,
    PaginationModule.forRoot(),
    NgxPageScrollCoreModule,
    ShareButtonsModule,
    ShareIconsModule,
    SweetAlert2Module.forRoot(),
    NgOtpInputModule,
    CountdownModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: getAuthServiceConfigs
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    TitleCasePipe
  ],
  bootstrap: [AppComponent],
  entryComponents: [OtpScreenComponent]
})
export class AppModule { }
