<section class="form-pages pt-120 bg-white pb-280 search-page">
<div class="container">
    <div class="search-div row">
        <div class="col-md-12">
        <h3><strong>Search</strong></h3>
        <div class="search-d-form relative mb-3">
        <input type="text" [(ngModel)]="searchText" placeholder="Enter your search here" class="form-control pr-5" (keydown.enter)='searchEntityWithin()'>
        <button (click)="searchEntityWithin()" class="btn btn-primary text-white"><i class="fa fa-search" aria-hidden="true"></i></button>
    </div>
        <!-- <h6>Advanced Search</h6> -->
    </div>
    </div>
    <div *ngIf="searchedDataCount > 0 else noData;">
    <div class="row" *ngFor="let item of seachDataToShow" [ngSwitch]="item?.entity">
        <div *ngSwitchCase="'application'" class="col-md-12 first-search">
            <div class="search-col-wrap">
            <h6>{{item?.name}}</h6>
            <p>Type : <b>{{item?.entity | titlecase}}</b></p>
            <p [innerHTML]="transform(item?.app_summary,200)"></p>
            <div class="search-read-btn"><button (click)="readMore(item)" class="btn btn-primary">Read More</button></div>
        </div>
        </div>
        <div *ngSwitchCase="'article'"  class="col-md-12">
            <div class="search-col-wrap">
            <h6>{{item?.title}}</h6>
            <p>Type : <b>{{item?.entity | titlecase}}</b></p>
            <p [innerHTML]="transform(item?.description,200)"></p>
            <div class="search-read-btn"><button (click)="readMore(item)" class="btn btn-primary">Read More</button></div>
        </div>
    </div>
        <div *ngSwitchCase="'casestudy'"  class="col-md-12">
          <div class="search-col-wrap">
            <h6>{{item?.title}}</h6>
            <p>Type : <b>{{item?.entity | titlecase}}</b></p>
            <p [innerHTML]="transform(item?.description,200)"></p>
            <div class="search-read-btn"><button (click)="readMore(item)" class="btn btn-primary">Read More</button></div>
        </div>
    </div>
        <div *ngSwitchCase="'interview'"  class="col-md-12">
          <div class="search-col-wrap">
            <h6>{{item?.title}}</h6>
            <p>Type : <b>{{item?.entity | titlecase}}</b></p>
            <p [innerHTML]="transform(item?.description,200)"></p>
            <div class="search-read-btn"><button (click)="readMore(item)" class="btn btn-primary">Read More</button></div>
        </div>
    </div>
        <div *ngSwitchCase="'news'"  class="col-md-12">
          <div class="search-col-wrap">
            <h6>{{item?.title}}</h6>
            <p>Type : <b>{{item?.entity | titlecase}}</b></p>
            <p [innerHTML]="transform(item?.description,200)"></p>
            <div class="search-read-btn"><a href="{{item?.link}}" target="_blank" class="btn btn-primary">Read More</a></div>
        </div>
    </div>
        <div *ngSwitchCase="'ngo'"  class="col-md-12">
          <div class="search-col-wrap">
            <h6>{{item?.name}}</h6>
            <p>Type : <b>{{item?.entity | titlecase}}</b></p>
            <p [innerHTML]="transform(item?.description,200)"></p>
            <div class="search-read-btn"><a  href="{{item?.website}}" target="_blank" class="btn btn-primary">Read More</a></div>
        </div>
    </div>
    </div>

    <div class="row pagination-row mt-5 mb-0" [hidden]="!showPagination">
        <div class="col-md-12 text-right">
            <div class="pagination justify-content-end">
                <div class="page-item">
                    <pagination [boundaryLinks]="true" [itemsPerPage]="itemsPerPage" [totalItems]="searchedDataCount"
                        [(ngModel)]="page" (pageChanged)="pageChanged($event)" [customPreviousTemplate]="prevTemplate"
                        [maxSize]="maxSize" [customNextTemplate]="nextTemplate" [customFirstTemplate]="firstTemplate"
                        [customLastTemplate]="lastTemplate"></pagination>
                    <ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
                        <ng-container *ngIf="!disabled">
                            Next
                        </ng-container>
                    </ng-template>

                    <ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
                        <ng-container *ngIf="!disabled">
                            Previous
                        </ng-container>
                    </ng-template>

                    <ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
                        <ng-container *ngIf="!disabled">
                            Last
                        </ng-container>
                    </ng-template>

                    <ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
                        <ng-container *ngIf="!disabled">
                            First
                        </ng-container>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #noData>
    <section class="form-pages pt-120 bg-white">
        <p class="text-center">No Data Found</p>
    </section>
</ng-template>
</div>
</section>