import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../../environments/environment";
// import { ToastrService } from "ngx-toastr";
import { BehaviorSubject } from 'rxjs';

const api = environment.apiBaseUrl;
// const baserUrl = environment.base;

@Injectable({ providedIn: "root" })
export class CategoriesService {

  private messageSource = new BehaviorSubject(true);
  currentMessage = this.messageSource.asObservable();

  private isNewsLetterSubscibed = new BehaviorSubject(false);
  toShownewsletterBox = this.isNewsLetterSubscibed.asObservable();


  constructor(
    private http: HttpClient,
    private router: Router,
    // private toastr: ToastrService
  ) { }

  setnewletterSuscription(val) {
    this.isNewsLetterSubscibed.next(val);
  }

  getAll(data) {
    return this.http.get(`${api}api/v1/list_category?page=1&pageSize=100`, data);
  }

  getAllCatSubCat(data) {
    return this.http.get(`${api}api/v1/category_with_subcategories`, data);
  }

  getAppDetails(data, appId) {
    return this.http.get(`${api}api/v1/view_application/${appId}`, data);
  }

  getAllSub(data) {
    return this.http.get(`${api}api/v1/list_subcategory?page=${data.page}&pageSize=${data.pageSize}`, data);
  }

  newsletterSubscription(data) {
    return this.http.post(`${api}api/v1/subscribe_newsletter`, data)
  }

  subscriptionVerification(data) {
    return this.http.post(`${api}api/v1/subscriber_verification`, data)
  }

  getAllApps(data, page) {
    if (data.subcategory_id && !data.app_type && !data.nameAndRating) {
      return this.http.get(`${api}api/v1/list_application?category_id=${data.category_id}&subcategory_id=${data.subcategory_id}&page=${page}&status=published`, data);
    }
    else if (data.subcategory_id && !data.app_type && data.nameAndRating) {
      return this.http.get(`${api}api/v1/list_application?category_id=${data.category_id}&subcategory_id=${data.subcategory_id}&page=${page}&sort_col=${data.sort_col}&sort_val=${data.sort_val}&status=published`, data);
    }
    else if (data.subcategory_id && data.app_type && !data.nameAndRating) {
      return this.http.get(`${api}api/v1/list_application?category_id=${data.category_id}&subcategory_id=${data.subcategory_id}&app_type=${data.app_type}&page=${page}&status=published`, data);
    }
    else if (data.subcategory_id && data.app_type && data.nameAndRating) {
      return this.http.get(`${api}api/v1/list_application?category_id=${data.category_id}&subcategory_id=${data.subcategory_id}&app_type=${data.app_type}&page=${page}&sort_col=${data.sort_col}&sort_val=${data.sort_val}&status=published`, data);
    }
    else if (!data.subcategory_id && data.app_type && !data.nameAndRating) {
      return this.http.get(`${api}api/v1/list_application?category_id=${data.category_id}&app_type=${data.app_type}&page=${page}&status=published`, data);
    }
    else if (!data.subcategory_id && data.app_type && data.nameAndRating) {
      return this.http.get(`${api}api/v1/list_application?category_id=${data.category_id}&app_type=${data.app_type}&page=${page}&sort_col=${data.sort_col}&sort_val=${data.sort_val}&status=published`, data);
    }
    else if (!data.subcategory_id && !data.app_type && data.nameAndRating) {
      return this.http.get(`${api}api/v1/list_application?category_id=${data.category_id}&page=${page}&sort_col=${data.sort_col}&sort_val=${data.sort_val}&status=published`, data);
    }
    else {
      return this.http.get(`${api}api/v1/list_application?category_id=${data.category_id}&page=${page}&status=published`, data);
    }
  }

  getApp(data) {
    return this.http.get(`${api}api/v1/list_application`, data);
  }

  getSearchData(data) {
    return this.http.get(`${api}api/v1/global_search?search=${data}`, data);
  }

  getAllStaticPages(data) {
    return this.http.get(`${api}api/v1/page/list`, data);
  }

  getAllNewsLetter(data){
     let api_url = `${api}api/v1/newsletters/users?pageNo=${data.page}&pageLength=${data.pageLength}`;
    if(data.searchText) {
      api_url += `&search=${data.searchText}`;
    }
    if(data.filterCategory){
      api_url += `&filterCategory=${data.filterCategory}`;
    }
    if(data.searchMonth){
      api_url += `&searchMonth=${data.searchMonth}`;
    }
    return this.http.get(api_url, data);
  }

  getNewsLetterById(id:any){
    return this.http.get(`${api}api/v1/get_preview/`+id)
  }

  //Notificatons
  getNotifications(id:any){
    return this.http.get(`${api}api/v1/notification/`+id)
  }

  readAllNotifications(id){
    return this.http.put(`${api}api/v1/notification/read_all/${id}`,'')
  }
  readSingleNotification(id){
    return this.http.put(`${api}api/v1/notification/read/${id}`,'')
  }
  deleteNotification(id){
    return this.http.delete(`${api}api/v1/notification/${id}`)
  }
}
