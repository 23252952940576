import { Component, OnInit, ViewChild ,Inject} from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { CategoriesService } from '../../shared/services/header.service';
import { environment } from "../../../environments/environment";
import {  Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-discover-app',
  templateUrl: './discover-app.component.html',
  styleUrls: ['./discover-app.component.scss']
})
export class DiscoverAppComponent implements OnInit {
  @ViewChild('focusCategory1', { static: true }) focusCategory1:any;
  itemsPerSlide = 3;
  itemsPerSlideFeatureApp = 3;
  cycleInterval = 3000;

  singleSlideOffset = true;
  noWrap = !true;
  activeCat: any = '';
  catList: any = [];
  subCatList: any = [];
  appList: any = [];
  filters: any = {};
  imgUrl: any;
  dummyImg = 'assets/img/discover-app/apps-logo.png'
  dummy = 'assets/img/discover-app/app-slider.jpg'
  appInfo: any = {};
  allAppsShow: Boolean = true;
  selectedSubCat: any = '';
  currentAppType:any;
  currentSubCategory:any = '';
  selectedAppType:any = '';
  currentActiveRouteParams:any;
  showPagination:boolean = false;

  totalItems: any;
  page: number = 1;
  itemsPerPage = 6;
  maxSize = 4;

  dummyImages = [
    'assets/img/app-screen.png',
    'assets/img/app-screen2.png',
    'assets/img/app-screen.png',
    'assets/img/app-screen2.png'
  ]

  slideConfig = {
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 2
  }

  slideConfigArticles = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    dots: false,
    centerMode: true,
    arrows: true,
       responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1

      }
    }
  ]
  }

  subscription = new Subject();
  subscription1 = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private categoriesService: CategoriesService,
    private pageScrollService: PageScrollService, 
    @Inject(DOCUMENT) private document: any,
    private titleService:Title
  ) {
    this.imgUrl = environment.imgUrl;
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(param => {
      if(param.id || param.subCat || param.app_type){
        this.currentActiveRouteParams = param;
        this.activeCat = param.id
        this.filters = {
          category_id: this.activeCat
        }
        this.getAllCat()
        if(param.subCat || param.app_type){
          this.calculateFilters(param);
          // this.currentSubCategory = param.subCat;
          // this.subCatSelected(this.currentSubCategory);
        }
        else if(!param.subCat && !param.app_type){
          this.getApps();
        }
      } else return
    })
  }


  getApps() {
    this.spinner.show();
    this.allAppsShow = true;
    this.categoriesService.getAllApps(this.filters,this.page).pipe(takeUntil(this.subscription)).subscribe(res => {
      if (res['status']) {
        this.showPagination = true;
        this.totalItems = res['applications_count'];
        this.appList = res['applications']
        this.spinner.hide();
      }
    })
  }

  getAllCat() {
    this.allAppsShow = true;
    this.categoriesService.getAllCatSubCat({}).pipe(takeUntil(this.subscription1)).subscribe(res => {
      if (res['status']) {

        this.catList = res['categories']
        let active = this.catList.filter(c => c._id === this.activeCat);
        this.titleService.setTitle(active[0].name+ ' | Social App Hub');
        let indexOfSelectedCat = this.catList.findIndex(_i => _i._id == this.activeCat);        
        setTimeout(()=>{
          this.focusCategory1.slickGoTo(indexOfSelectedCat);
        },10)
        this.subCatList = active[0]['subcategories'];
      }
    })
  }

  subCatSelected(_id) {
    this.currentSubCategory = _id;
    this.selectedSubCat = _id;
    delete this.filters['subcategory_id']
    this.filters = Object.assign({
      subcategory_id: this.selectedSubCat
    }, this.filters)
    this.getApps();
  }
  calculateFilters(params){
    if(params.app_type && params.subCat){
      this.filters = Object.assign({
        subcategory_id:params.subCat,
        app_type: params.app_type
      }, this.filters)
      this.currentSubCategory = params.subCat;
      this.selectedSubCat = params.subCat;
      this.selectedAppType = params.app_type;
    }
    else if(!params.app_type && params.subCat){
      this.filters = Object.assign({
        subcategory_id:params.subCat,
      }, this.filters)
      this.currentSubCategory = params.subCat;
      this.selectedSubCat = params.subCat;
    }
    else if(params.app_type && !params.subCat){
      this.filters = Object.assign({
        app_type: params.app_type
      }, this.filters)
      this.selectedAppType = params.app_type;
    }
    this.getApps();
  }

  categorySelected(id) {
    this.router.navigate(['/discover-app'] , { queryParams: { id: id } });
    // this.gotoTop();
    // this.filters = {
    //   category_id: id
    // }
    // this.activeCat = id;
    // this.getAllCat();
    // this.getApps();
  }

  appDetails(id) {
    if(this.selectedAppType != '' && this.currentSubCategory !=''){
      this.router.navigate(['/discover-app-detail'], { queryParams: { id: this.activeCat , appId:id, app_type:this.selectedAppType,subCat:this.currentSubCategory} })
    }
    else if(this.selectedAppType && this.currentSubCategory == ''){
      this.router.navigate(['/discover-app-detail'], { queryParams: { id: this.activeCat , appId:id, app_type:this.selectedAppType} })
    }
    else if(this.selectedAppType == '' && this.currentSubCategory){
      this.router.navigate(['/discover-app-detail'], { queryParams: { id: this.activeCat , appId:id, subCat:this.currentSubCategory} })
    }
    else{
    this.router.navigate(['/discover-app-detail'], { queryParams: { id: this.activeCat , appId:id} })
  }

    // this.spinner.show();
    // let active = this.appList.filter(c => c._id === id);
    // this.appInfo = active[0];

    // this.allAppsShow = false;
    // this.spinner.hide();
  }
  appTypeSelected(name){
    this.selectedAppType = name;

    delete this.filters['app_type']
    this.filters = Object.assign({
      app_type: this.selectedAppType
    }, this.filters)

    this.getApps();
  }
  gotoTop() {
    window.scroll({
      top: 0,
      // left: 0,
      behavior: 'smooth' ,
    });
  }

  resetOption(){
    this.currentSubCategory = ''
    this.selectedSubCat = ''
    this.selectedAppType = ''
    if( this.currentActiveRouteParams.subCat || this.currentActiveRouteParams.app_type ){
      this.router.navigate(['/discover-app'] , { queryParams: { id: this.activeCat } });
      return;
    }
    this.filters = {};
    this.filters = {
      category_id: this.activeCat
    }
    this.getApps();
  }

  pageChanged(event: any): void {
    this.page = event.page;
    this.pageScrolling('.theStartContent')
    this.getApps();
  }
  ngOnDestroy(){
    this.subscription1.unsubscribe();
    this.subscription.unsubscribe();
  }
  pageScrolling(nameOfClass){
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: nameOfClass
    });
  }
  filterDiscoverApps(data,type){
    console.log(data.target.value,type);
    this.selectedAppType = name;
    delete this.filters['nameAndRating'];
    delete this.filters['sort_col'];
    delete this.filters['sort_val']; 
    this.filters = Object.assign({
      nameAndRating:true,
      sort_col: type,
      sort_val: data.target.value
    }, this.filters)
    console.log(">>>>>>>>>>>>>",this.filters);
    this.getApps();

  }

}
