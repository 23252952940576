import { LocalStorageService } from './../../../shared/storage/local-storage.service';
import { whiteSpace } from './../../../shared/validations/whiteSpace';
import { Component, OnInit, ViewChild, ViewChildren, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { AuthorisationService } from './../../../authentication/services/auth.service';
import { APP_ROUTES } from './../../../shared/routes/appRoutes';
import { ToastrService } from 'ngx-toastr';
import {
	AuthService,
	SocialUser,
	FacebookLoginProvider,
	GoogleLoginProvider
} from 'angular5-social-login';
import { Title } from '@angular/platform-browser';
import { CounterDirective } from './timer.directive';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';



// import { btnConfig, DateConfig } from '../../../interfaces';
// import { dateFormatter } from '../../../shared/utility';

@Component({
	selector: 'app-register',
	templateUrl: './register2.html',
	styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
	@ViewChild('countdown', { static: true }) private countdown: CountdownComponent;

	public APP_ROUTES = APP_ROUTES;
	public registerForm: FormGroup;
	public isSubmitting: boolean = false;
	public isDisabled: boolean = false;
	public passwordVisibility: boolean = false;
	showotpScreen: boolean = false;
	step1registrationCompleted: boolean = false;
	step1registrationstarted: boolean = false;
	displayResendOtpButton: boolean = false;
	otpConfig = {
		length: 4
	}
	config: CountdownConfig = {
		leftTime: 60,
		demand: true
	}
	setting = {
		timer: 0
	};
	userId: any;
	userNumber: any;
	public counter: number;

	@ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
	// @ViewChildren(CounterDirective) CounterDirective;

	constructor(private router: Router,
		private auth: AuthorisationService,
		private toastr: ToastrService,
		private socialAuthService: AuthService,
		private localStorage: LocalStorageService,
		private titleService: Title,
	) { }

	ngOnInit(): void {
		this.titleService.setTitle('Registration | Social App Hub');
		this.initForm();
		// this.registerForm.get('email').valueChanges.subscribe(event => {
		// 	this.registerForm.get('email').setValue(event.toLowerCase(), { emitEvent: false });
		// });
	}

	private initForm(): void {
		this.registerForm = new FormGroup({
			'mobile': new FormControl('', [Validators.required, Validators.pattern(/^(?:[+0]9)?[0-9]{10,12}$/)]),
			'password': new FormControl('', [Validators.minLength(6), Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)]),
			'channel': new FormControl('')
		}, { updateOn: 'blur' });
	}
	get f() {
		return this.registerForm.controls;
	}
	step1Registration() {
		if (this.registerForm.invalid) {
			this.isSubmitting = true;
			return;
		}
		let payload = {
			mobile: this.registerForm.get('mobile').value,
			countryCode: '91',
			type: 'signUp'
		}
		this.auth.otpregister(payload).subscribe((res: any) => {
			if (res.status) {
				setTimeout(() => {
					this.countdown.begin();
					this.step1registrationstarted = true;
					this.userId = res.user_id;
					console.log(this.userId);
					this.isSubmitting = false
					this.toastr.success('Otp has been sent to your Phone', '');
				}, 1000);

			}
		}, err => {
			this.toastr.error(err.error.message, '');
		});
	}
	public doRegister(e: number): void {
		if (this.registerForm.invalid) {
			this.isSubmitting = true;
			return;
		}
		this.isSubmitting = false;
	
		const payload ={
			password: this.registerForm.get('password').value,
			mobile:  this.registerForm.get('mobile').value, 
			countryCode: '+91'};
		this.auth.register(payload).subscribe((res: any) => {
			if (res.status === 200) {
				setTimeout(() => {
					this.userId = res.body.user_id;
					this.toastr.success(res.body.message, '');
				}, 1000);
				this.isSubmitting = false;
				this.registerForm.reset();
				this.registerForm.get('mobile').enable();
				this.router.navigate([APP_ROUTES.profile])
			}
		}, err => {
			this.toastr.error(err.error.message, '');
		});
	}

	otpScreenWork() {

	}
	onOtpChange(event) {
		if (event.length < this.otpConfig.length) {
		}
		else if (event.length == this.otpConfig.length) {
			let payload = {
				user_id: this.registerForm.get('mobile').value,
				otp: event
			}
			this.auth.checkOtp(payload).subscribe((data) => {
				if (data.status) {
					console.log(data)
					this.showotpScreen = false;
					this.toastr.success(data.msg);
					this.registerForm.get('mobile').disable();
					this.registerForm.get('password').setValidators([Validators.required,Validators.minLength(6), Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)]);
					this.step1registrationCompleted = true;
					this.step1registrationstarted = false;
				}
			}, err => {
				this.toastr.error(err.error.msg, '');
			})
		}
	}

	resendOtp() {
		this.countdown.restart();
		this.countdown.begin();
		// this.CounterDirective.first.startTimer();
		let payload = {
			mobile: this.registerForm.get('mobile').value,
			countryCode: '91',
			type: 'resendOtp'
		}
		this.auth.otpregister(payload).subscribe((data) => {
			if (data.status) {
				console.log(data);
				this.userId = data.user_id;
				this.toastr.success(data.message);
				// [Validators.required, Validators.minLength(6), Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)]
			}
		}, err => {
			this.toastr.error(err.error.message, '');
		})

	}

	preparePayload() {
		// const fname: string = this.registerForm.get('firstName').value.trim();
		// const lname: string = this.registerForm.get('lastName').value.trim();
		// this.registerForm.get('firstName').setValue(fname);
		// this.registerForm.get('lastName').setValue(lname);
		// "first_name": fname,
		// "last_name": lname,
		// "email": this.registerForm.get('email').value,
		let payload = {
			"mobile": this.registerForm.get('mobile').value,
			"password": this.registerForm.get('password').value,
			"countryCode": '+91',
			"channel": 'manual'
		};
		return payload;
	}


	public socialSignIn(socialPlatform: string) {
		let channel = ''

		let socialPlatformProvider;
		if (socialPlatform == "facebook") {
			channel = 'facebook'
			socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
		} else if (socialPlatform == "google") {
			channel = 'google'
			socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
		}

		let socialLoginPayload: any;
		this.socialAuthService.signIn(socialPlatformProvider).then(
			(userData) => {
				// Now sign-in with userData
				if (userData != null) {
					socialLoginPayload = {
						name: userData.name,
						email: userData.email,
						img: userData.image,
						provider: userData.provider,
						channel: channel
					};
					// this.authorized = true;
					// this.user = userData;
					this.checkStatus(socialLoginPayload);
				}
			}
		);
	}

	checkStatus(socialLoginPayload: any) {
		this.auth.checkUserStatus(socialLoginPayload).subscribe((response: any) => {
			if (response.status === 200) {
				if (response.body.password_state === 'resolved') {
					this.localStorage.setItem('token', response.body.user.token);
					this.localStorage.setItem('currentUser', JSON.stringify(response.body.user));
					// this.router.navigate([APP_ROUTES.profile]).then(() => {
					// });
					this.router.navigate([APP_ROUTES.root]).then(() => {
					});
				}
				if (response.body.password_state === 'pending') {
					this.localStorage.setItem('password_state', 'pending');
					this.localStorage.setItem('token', response.body.user.token);
					this.localStorage.setItem('currentUser', JSON.stringify(response.body.user));
					// this.router.navigate([APP_ROUTES.setSocialPassword]).then(() => {
					// });
					this.router.navigate([APP_ROUTES.root]).then(() => {
					});
				}
			}
			else if (response.status === 403) {
				const title: string = 'Account Blocked';
				const html: string = 'Your account has been blocked by admin';
				this.toastr.error('Your account has been blocked by admin', 'Account Blocked');
			}
		}, err => {
			if (err.error.message) {
				this.toastr.error(err.error.message, '');
			}
			else {
				this.toastr.error('Something went wrong. Please try again later', '');
			}
		});
	}
	checkPublicEmail = (control) => {
		return new Promise((resolve, reject) => {
			const allowedDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com', 'mailinator.com', 'yopmail.com']
			if (control.value.includes('@')) {
				let domain = control.value.split('@');
				if (domain.length == 2) {
					if (allowedDomains.includes(domain[1])) {
						resolve(null)
					} else {
						resolve({ invalid: true })
					}
				}
				else {
					resolve(null)
				}
			}
			else {
				resolve(null);
			}
		});
	}
	public onCounterChange(e): void {
		this.counter = e;
		if (this.counter == 0) {
			// this.onValueChange.emit(-1);
		}
	}

	myNumberFunction($event) {
		if ($event.which != 8 && $event.which != 0 && $event.which < 48 || $event.which > 57) {
			$event.preventDefault();
		}

	}
	handleEvent(event) {
		console.log(event);
		if (event.action == 'start') {
			this.displayResendOtpButton = false
		}
		else if (event.action == 'done') {
			this.displayResendOtpButton = true
		}

	}
}
