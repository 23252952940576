import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

@Injectable({
	providedIn: 'root'
})
export class SwalService {

	constructor() { }

	// Simple Alert
	basicAlert(msg) {
		swal.fire({
			text: msg,
			customClass: {
				confirmButton: 'confirm-button-class',
			},
			position: 'top'
		})
    }

    //Basic info
    basicInfo(msg){
        swal.fire({
            text: msg,
			confirmButtonColor: '#e03a3c',
			allowOutsideClick: false,
			backdrop: false
		})
        // swal.fire("", msg, "info");
    }
	// Alert with Title
	withTitle(title: string, text: string) {
		swal.fire({
			title: title,
			text: text,
			customClass: {
				confirmButton: 'confirm-button-class',
			},
			position: 'top'
		})
	}
	//  HTML Alert
	htmlAlert() {
		swal.fire({
			title: 'HTML <small>Title</small>!',
			html: 'A custom <span style="color:#F6BB42">html<span> message.'
		});
	}
	// Question Type Alert
	typeQuestion() {
		swal.fire("Question", "Are You Sure?", "question");
	}
	// Success Type Alert
	typeSuccess() {
		swal.fire("Good job!", "You clicked the button!", "success");
	}
	// Info Type Alert
	typeInfo() {
		swal.fire("Info!", "You clicked the button!", "info");
	}
	// Warning Type Alert
	typeWarning() {
		swal.fire("Warning!", "You clicked the button!", "warning");
	}
	// Error Type Alert
	typeError() {
		swal.fire("Error!", "You clicked the button!", "error");
	}
	// Custom Icon
	customIcon() {
		swal.fire({ title: "Sweet!", text: "Here's a custom image.", imageUrl: "./assets/img/portrait/avatars/avatar-08.png" });
	}
	// Auto close timer
	autoClose() {
		swal.fire({ title: "Auto close alert!", text: "I will close in 2 seconds.", timer: 2000, showConfirmButton: false });
	}
	// Allow Outside Click
	outsideClick() {
		swal.fire({
			title: 'Click outside to close!',
			text: 'This is a cool message!',
			allowOutsideClick: true
		});
	}







}
