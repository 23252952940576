import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    title = 'social-hub-web';
    public showContent: boolean = false;

    public ngOnInit() {
        setTimeout(() => this.showContent=true, 1000);
    }

}
