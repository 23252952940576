import { Injectable, Inject, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

    public clear(): void {
        // if (isPlatformBrowser(this.platformId)) { }
        localStorage.clear();
        sessionStorage.clear();
    }

    public getItemString(key: string): string {
        return localStorage.getItem(key) || sessionStorage.getItem(key);
    }

    public isRememberMe(): boolean {
       return  localStorage.getItem('currentUser') ? true : false ;
        // return localStorage.getItem(key) || sessionStorage.getItem(key);
    }

    public getItem(key: string) {
        const data = localStorage.getItem(key) || sessionStorage.getItem(key);
        if (key === 'token') {
            return data;
        }
        return JSON.parse(data);
    }

    public removeItem(key: string): void {
        localStorage.removeItem(key);
        sessionStorage.removeItem(key);
    }

    public setItem(key: string, data: string, rememberMe?: boolean): void {
        if(rememberMe) {
            localStorage.setItem(key, data);
        } else {
            sessionStorage.setItem(key, data);
        }
    }
}
