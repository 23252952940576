<!-- ======= Header ======= -->
<header id="header" class="fixed-top">
    <div class="container-fluid d-flex align-items-center">
        <h1 class="logo mr-auto">
            <a routerLink="/"><img src="/assets/img/logo.png" /></a>
        </h1>
        <button class="navbar-toggler toggler-example" type="button" id="hamburgerBtn" aria-expanded="false" aria-label="Toggle navigation">
            <span class="dark-blue-text"><i class="fa fa-bars" aria-hidden="true"></i>
            </span>
        </button>
        <ng-container *ngIf="isDesktop">
            <nav #hamburger class="nav-menu relative">

                <ul>
                    <!-- <li routerLinkActive="active">
                        <a routerLink="/">Home</a>
                    </li> -->
                    <li routerLinkActive="active">
                        <a routerLink="/info/our-story">Our Story</a>
                    </li>
                    <li routerLinkActive="active">
                        <a routerLink="/knowledge-section/news">News</a>
                    </li>
                    <li class="drop-down" routerLinkActive="active" hover-class="open-submenu">
                        <a>Discover Apps
                            <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                        <ul>
                            <li *ngFor="let cat of catList">
                                <a [routerLink]="['/discover-app']" [queryParams]="{ id: cat.id }">{{ cat.name }}</a>
                            </li>
                        </ul>
                    </li>
                    <li class="drop-down" routerLinkActive="active" hover-class="open-submenu">
                        <a>Knowledge
                            <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                        <ul>
                            <li>
                                <a routerLink="knowledge-section/case-study">Case Study</a>
                            </li>
                            <li>
                                <a routerLink="knowledge-section/interview">Interview</a>
                            </li>
                            <li>
                                <a routerLink="knowledge-section/articles">Articles</a>
                            </li>
                            <li>
                                <a routerLink="knowledge-section/ngo">NGO Of Month</a>
                            </li>
                        </ul>
                    </li>
                    <li class="drop-down" routerLinkActive="active" hover-class="open-submenu" *ngIf="staticpages?.length > 0">
                        <a>Info Pages<i class="fa fa-caret-down" aria-hidden="true"></i></a>
                        <ul>
                            <li *ngFor="let page of staticpages"><a [routerLink]="['/info/page/', page.type]">{{ page.title }}</a></li>
                        </ul>
                    </li>
                    <!-- <li routerLinkActive="active" *ngIf="showSignOut">
                        <a routerLink="newsletters">Newsletters</a>
                    </li> -->
                    <li *ngIf="showSignOut">
                        <div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="false">
                            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu notificationDropdown" role="menu" aria-labelledby="button-basic">
                                <li role="menuitem" class="header-notification">
                                    <div>Notifications
                                        <div class="new-count">New <span>{{notificationCount}}</span></div>
                                    </div>
                                    <div class="btn-group" dropdown #dropdown1="bs-dropdown" [autoClose]="true" [hidden]="!(notificationCount > 0)">
                                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu">
                                            <li role="menuitem"><a class="dropdown-item" href="javascript:;" (click)="readAllNotifications()">Read All</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <a href="javascript:void(0)" class="notification-option" *ngIf="notificationCount > 0" (click)="dropdown1.isOpen = !dropdown1.isOpen"><i class="fa fa-sliders"
                                            aria-hidden="true"></i>
                                    </a>
                                </li>
                                <li role="menuitem" class="listViewNotification" *ngFor="let item of notificationList let indexNotification = index" [ngClass]="item?.is_read ? 'readNotification' : ''">
                                    <div class="actionNotification">
                                        <a href="javascript: void(0)" title="Mark As Read" *ngIf="!item?.is_read" (click)="notificationEvent(indexNotification,'read',item?._id)"><i class="fa fa-bookmark-o" aria-hidden="true"></i></a>
                                        <a href="javascript: void(0)" class="text-danger" title="Delete" (click)="notificationEvent(indexNotification,'delete',item?._id)"><i class="fa fa-trash"></i></a>
                                    </div>
                                    <a class="dropdown-item" href="{{item.url}}" target="_blank">
                                        <div class="noti-thumb"> <img src="{{imageUrl}}{{item?.image}}"></div>
                                        <div class="noti-content w-100 pl-2">
                                            <h3> {{item?.title}} </h3>
                                            <p [innerHtml]="transform(item?.message)"></p>
                                            <p>Category: {{item?.category?.name}}</p>
                                            <span><i class="fa fa-clock-o mr-1"></i>
                                            {{item?.createdAt | date:'dd MMM'}} &nbsp;</span>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <button type="button" class="btn btn-primary notify-btn" (click)="dropdown.isOpen = !dropdown.isOpen"><i
                                class="fa fa-bell" aria-hidden="true"></i><span>{{notificationCount}}</span>
                        </button>
                    </li>

                    <!-- <li class="drop-down" *ngIf="showSignOut" routerLinkActive="active">
                        <a routerLink="/app/submitApp">Submit App</a>
                    </li> -->
                    <li *ngIf="showSignUp && !loginOrRegister">
                        <a href="javascript:;" class="get-started-btn rounded-pill" (click)="signUp()">Sign Up/Sign
                            In</a>
                    </li>
                    <li *ngIf="!showSignOut && loginOrRegister">
                        <a class="get-started-btn rounded-pill" (click)="signIn()">Sign Up/Sign In</a>
                    </li>
                    <!-- <li *ngIf="showSignOut">
                        <a class="get-started-btn rounded-pill">My Apps</a>
                    </li> -->

                    <!-- <li *ngIf="showSignOut">
                        <a routerLink="/profile" class="get-started-btn rounded-pill">Profile</a>
                    </li> -->
                    <li *ngIf="showSignOut" class="drop-down" routerLinkActive="active" hover-class="open-submenu">
                        <a class="get-started-btn rounded-pill">P
                            <i class="fa fa-caret-down" aria-hidden="true"></i></a>
                        <ul>
                            <li *ngIf="showSignOut"><a routerLink="/profile">My Profile</a> </li>
                            <li *ngIf="showSignOut"> <a routerLink="/app/allApps"> My apps</a></li>
                            <li *ngIf="showSignOut"><a routerLink="/app/submitApp"> Submit App</a></li>
                            <li *ngIf="showSignOut "> <a routerLink="newsletters">  Newsletter</a></li>
                            <li *ngIf="showSignOut "><a [routerLink]="[ '/discover-app'] " (click)="signOut() "> Logout</a></li>
                        </ul>
                    </li>
                    <!-- <li *ngIf="showSignOut ">
                        <a href="javascript:; " class="get-started-btn rounded-pill " >Sign Out</a>
                    </li> -->
                </ul>
                <div class="header-search " [ngClass]="showSearchBar? 'active': '' ">
                    <input type="text " [(ngModel)]="searchText " placeholder="Type search text and hit enter " (keydown.enter)='searchEntity()'>
                    <button (click)="searchEntity() "><i class="fa fa-search " aria-hidden="true "></i></button>
                </div>
            </nav>

        </ng-container>
        <nav #mobileMenu class="nav-menu d-none mob-nav ">
            <ul>
                <li routerLinkActive="active ">
                    <a routerLink="/ " (click)="closeNavBar($event) ">Home</a>
                </li>
                <li routerLinkActive="active ">
                    <a routerLink="/info/our-story " (click)="closeNavBar($event) ">Our Story</a>
                </li>
                <li routerLinkActive="active ">
                    <a routerLink="/knowledge-section/news " (click)="closeNavBar($event) ">News</a>
                </li>
                <li class="drop-down " routerLinkActive="active " (click)="handleSubmenu( 'discover') " [ngClass]="{ 'open-submenu': activeSubmenu=='discover' } ">
                    <a>Discover Apps
                        <i class="fa fa-caret-down " aria-hidden="true "></i></a>
                    <ul>
                        <li *ngFor="let cat of catList ">
                            <a [routerLink]="[ '/discover-app'] " [queryParams]="{ id: cat.id } " (click)="closeNavBar($event) ">{{ cat.name }}</a>
                        </li>
                    </ul>
                </li>
                <li class="drop-down " routerLinkActive="active " (click)="handleSubmenu( 'knowledge') " [ngClass]="{ 'open-submenu': activeSubmenu=='knowledge' } ">
                    <a>Knowledge
                        <i class="fa fa-caret-down " aria-hidden="true "></i></a>
                    <ul>
                        <li>
                            <a (click)="closeNavBar($event) " routerLink="knowledge-section/case-study ">Case Study</a>
                        </li>
                        <li>
                            <a (click)="closeNavBar($event) " routerLink="knowledge-section/interview ">Interview</a>
                        </li>
                        <li>
                            <a (click)="closeNavBar($event) " routerLink="knowledge-section/articles ">Articles</a>
                        </li>
                        <li>
                            <a (click)="closeNavBar($event) " routerLink="knowledge-section/ngo ">NGO Of Month</a>
                        </li>
                    </ul>
                </li>

                <li class="drop-down " routerLinkActive="active " hover-class="open-submenu " *ngIf="staticpages?.length> 0">
                    <a>Informatory Pages<i class="fa fa-caret-down" aria-hidden="true"></i></a>
                    <ul>
                        <li *ngFor="let page of staticpages"><a [routerLink]="['/info/page/', page.type]">{{ page.title }}</a></li>
                    </ul>
                </li>

                <li class="drop-down" *ngIf="showSignOut" routerLinkActive="active">
                    <a routerLink="/app/submitApp" (click)="closeNavBar($event)">Submit App</a>
                </li>
                <li *ngIf="showSignUp && !loginOrRegister">
                    <a href="javascript:;" class="get-started-btn rounded-pill" (click)="signUp(); closeNavBar($event);">Sign Up/Sign In</a>
                </li>
                <li *ngIf="!showSignOut && loginOrRegister">
                    <a class="get-started-btn rounded-pill" (click)="signIn();closeNavBar($event)">Sign Up/Sign In</a>
                </li>
                <li *ngIf="showSignOut">
                    <a (click)="closeNavBar($event)" routerLink="/app/allApps" class="get-started-btn rounded-pill">My
                        Apps</a>
                </li>

                <li *ngIf="showSignOut">
                    <a (click)="closeNavBar($event)" routerLink="newsletters" class="get-started-btn rounded-pill">Newsletter</a>
                </li>
                <li *ngIf="showSignOut">
                    <a (click)="closeNavBar($event)" routerLink="/app/allApps" class="get-started-btn rounded-pill">Notifications</a>
                </li>

                <li *ngIf="showSignOut">
                    <a (click)="closeNavBar($event)" routerLink="/profile" class="get-started-btn rounded-pill">Profile</a>
                </li>
                <li *ngIf="showSignOut">
                    <a href="javascript:;" class="get-started-btn rounded-pill" (click)="signOut();closeNavBar($event)">Sign Out</a>
                </li>
            </ul>
            <div class="header-search" [ngClass]="showSearchBar? 'active': ''">
                <input type="text" [(ngModel)]="searchText" placeholder="Type search text and hit enter">
                <button (click)="searchEntity()"><i class="fa fa-search" aria-hidden="true"></i></button>
            </div>
        </nav>

    </div>
</header>