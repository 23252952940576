import { PreAuthService } from './../pre-auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { APP_ROUTES } from './../../../shared/routes/appRoutes';
import { ApiRoutes } from './../../../shared/routes/apiRoutes';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { CountdownComponent, CountdownConfig } from 'ngx-countdown';
import { AuthorisationService } from 'src/app/authentication/services/auth.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
	styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
	@ViewChild('countdown', { static: true }) private countdown: CountdownComponent;
	public APP_ROUTES = APP_ROUTES;
	public forgotForm: FormGroup;
	public isSubmitting: boolean = false;
	public isDisabled: boolean = false;
	isForgotSuccess: boolean = false;
	showError: boolean = false;
	step1registrationCompleted: boolean = false;
	step1registrationstarted: boolean = false;
	displayResendOtpButton: boolean = false;
	public passwordVisibility: boolean = false;
	isEmailEnter:boolean = false;
	otpConfig = {
		length: 4
	}
	countDownConfig: CountdownConfig = {
		leftTime: 60,
		demand: true
	}
	userId: any;
	submitUserId: any;
	constructor(private router: Router,
		private service: PreAuthService,
		private toastr: ToastrService,
		private titleService: Title, private auth: AuthorisationService, ) { }

	ngOnInit(): void {
		this.titleService.setTitle('Forgot Password | Social App Hub');
		this.initForm();
	}

	private initForm(): void {
		this.forgotForm = new FormGroup({
			'email': new FormControl('', [Validators.required]),
			'password': new FormControl('')
		}, { updateOn: 'blur' });
		// , Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)
	}

	doForgot(e: number): void {
		this.showError = false;
		this.isEmailEnter = false;
		this.isForgotSuccess = false;
		if (this.forgotForm.invalid) {
			this.isSubmitting = true;
			return;
		}
		const pattern1 = /^-?(([0-9]*)|(([0-9]*)\.([0-9]*)))$/;
		let numberTest = pattern1.test(this.forgotForm.value.email);
		if (numberTest) {
			const pattern3 = /^(?:[+0]9)?[0-9]{10,12}$/;
			let numberTest1 = pattern3.test(this.forgotForm.value.email);
			if (!numberTest1) {
				this.showError = true;
				return;
			}
			else {
				this.step1registrationstarted = true;
			}
		}
		else {
			// this.showError = true;
			// 	return;
			const pattern2 = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
			let emailTest = pattern2.test(this.forgotForm.value.email);
			if (!emailTest) {
				this.showError = true;
				return;
			}
			else{
				this.isEmailEnter = true;
			}
			
		}
		this.isSubmitting = false;
		let payload = {
			email: this.forgotForm.get('email').value,
		}
		this.service.forgotPassword(payload).subscribe((data: any) => {
			if (data.status === 200) {
				if(this.isEmailEnter){
					this.toastr.success(data.body.message,'')
					this.router.navigate([APP_ROUTES.login]);
				}
				else{
					setTimeout(() => {
						this.userId = data.body.user_id;
						this.submitUserId = data.body.id;
						this.step1registrationstarted = true;
						this.toastr.success(data.body.message, '');
						this.forgotForm.get('email').disable();
					}, 1000);
					this.isForgotSuccess = true;
					this.countdown.begin();
				}
				// this.router.navigate([APP_ROUTES.login]).then(() => {
				// 	// for some async task
				// });
			}
		}, err => {
			this.step1registrationstarted = false;
			this.toastr.error(err.error.message, '');
		});
	}


	doForgotSubmission(e: number): void {
		this.showError = false
		this.isForgotSuccess = false;
		// if (this.forgotForm.invalid) {
		// 	this.isSubmitting = true;
		// 	return;
		// }

		const pattern1 = /^-?(([0-9]*)|(([0-9]*)\.([0-9]*)))$/;
		let numberTest = pattern1.test(this.forgotForm.value.email);
		if (numberTest) {
			const pattern3 = /^(?:[+0]9)?[0-9]{10,12}$/;
			let numberTest1 = pattern3.test(this.forgotForm.value.email);
			if (!numberTest1) {
				this.showError = true;
				return;
			}
		}

		let payload = {
			"user_id": this.submitUserId,
			"password": this.forgotForm.get('password').value,
		}
		this.auth.registerPassword(payload).subscribe((data: any) => {
			if (data.status) {
				this.toastr.success(data.body.message)
				this.router.navigate([APP_ROUTES.login]);
			}
		}, err => {
			this.toastr.error(err.error.message, '');
		});
	}


	onOtpChange(event) {
		if (event.length < this.otpConfig.length) {
		}
		else if (event.length == this.otpConfig.length) {
			let payload = {
				user_id: this.forgotForm.get('email').value,
				otp: event
			}
			// this.step1registrationCompleted = true;
			// this.step1registrationstarted = false;
			// this.forgotForm.get('password').setValidators([Validators.required,Validators.minLength(6), Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)]);

			this.auth.checkOtp(payload).subscribe((data) => {
				if (data.status) {
					this.toastr.success(data.msg);
					this.forgotForm.get('password').setValidators([Validators.required, Validators.minLength(6), Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)]);
					this.step1registrationCompleted = true;
					this.step1registrationstarted = false;
				}
			}, err => {
				this.toastr.error(err.error.msg, '');
			})
		}
	}

	resendOtp() {
		this.countdown.restart();
		this.countdown.begin();
		// this.CounterDirective.first.startTimer();
		// let payload = {
		// 	user_id: this.userId
		// }
		let payload = {
			mobile: this.forgotForm.get('email').value,
			countryCode: '91',
			type:'resendOtp'
		}
		this.auth.otpregister(payload).subscribe((data) => {
			if (data.status) {
				this.userId = data.user_id;
				this.toastr.success(data.message);
				// [Validators.required, Validators.minLength(6), Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)]
			}
		}, err => {
			this.toastr.error(err.error.message, '');
		})

	}

	handleEvent(event) {
		if (event.action == 'start') {
			this.displayResendOtpButton = false
		}
		else if (event.action == 'done') {
			this.displayResendOtpButton = true
		}

	}

}
