import { Component, OnInit, Inject } from '@angular/core';
import { CategoriesService } from '../../services/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent implements OnInit {
  searchText:string = '';
  externalSearchText:string ='';
  showPagination:boolean = false
  subscription = new Subject();
  subscription1 = new Subject();
  searchedData = [];
  seachDataToShow = [];
  searchedDataCount:number;
  page: number = 1;
  itemsPerPage = 10;
  maxSize = 4;
  

  constructor(private route: ActivatedRoute,private categoriesService: CategoriesService, private router: Router, private sanitized: DomSanitizer,
    private pageScrollService: PageScrollService, 
    @Inject(DOCUMENT) private document: any,private titleService:Title) { }

  ngOnInit(): void {
    this.titleService.setTitle("Search | Social App Hub");
    this.route.queryParams.pipe(takeUntil(this.subscription)).subscribe(params =>{
      if(params.query){
      this.searchText = params.query;
      this.externalSearchText = params.query;
      this.searchData();
    }
    else this.searchText = 'No Search Text';
    })
  }
  searchData(){
      this.categoriesService.getSearchData(this.searchText).pipe(takeUntil(this.subscription1)).subscribe(res => {
    if(res['status']) {
      if(res['result'].length > 0){
        this.showPagination = true;
      }
      else{
        this.showPagination = false;
      }
      this.searchedDataCount = res['result'].length;
      this.searchedData = res['result'];      
      this.seachDataToShow = this.paginateArray(this.searchData,this.itemsPerPage,this.page);
    }
  })
  }

paginateArray(array, page_size, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return this.searchedData.slice((page_number - 1) * page_size, page_number * page_size);
  }

ngOnDestroy() {
  this.subscription.unsubscribe();
  this.subscription1.unsubscribe();
}
searchEntityWithin(){
  if(this.searchText == '' || this.searchText == ' '){
    this.searchText = this.externalSearchText;
    return;
  }
  if(this.searchText){
  this.router.navigate(['/search'], { queryParams: {query:this.searchText} });
}
}

pageChanged(event: any): void {
  this.page = event.page;
  // this.pageScrolling('.scrollingToTop')
  this.gotoTop()
  this.seachDataToShow = this.paginateArray(this.searchData,this.itemsPerPage,this.page);

}
// transform(value) {
//   if (value) {
//     return this.sanitized.bypassSecurityTrustHtml(value);
//   }
// }
transform(value,length) {
  if (value) {
    if(value.length > length){
      let data:any = value.replace(/<.*?>/g, '');            
      return data.substring(0,length) + '....'
    }
    else{
      return value;
    }
    
  }
}
readMore(item){
switch(item.entity){
  case 'application':{
    this.router.navigate(['/discover-app-detail'], { queryParams: { id: item['category'][0]['category_id'] , appId:item['_id']} })  
      break;
  }
  case 'article':{
    this.router.navigate(['/knowledge-section/article/detail',item['_id']])
    break;
  }
  case 'casestudy':{
    this.router.navigate(['/knowledge-section/case-study/detail', item['_id']])
    break;
  }
  case 'interview':{
    this.router.navigate(['/knowledge-section/interview/detail',item['_id']])
    break;
  }
  default: {
    break;
  }
}
}
gotoTop() {
  window.scroll({
    top: 0,
    // left: 0,
    behavior: 'smooth' ,
  });
}

pageScrolling(nameOfClass){
  this.pageScrollService.scroll({
    document: this.document,
    scrollTarget: nameOfClass
  });
}
}
