import { Component, OnInit, TemplateRef } from '@angular/core';
import { CategoriesService } from '../../services/header.service';
import { SwalService } from '../../services/swal/swal.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../storage/local-storage.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  catList: any = [];
  public subscriptionForm: FormGroup;
  subscriptionFormSubmitted: boolean = false;
  tempSubscriptionMessage = ''
  bsModalRef: BsModalRef;
  unsubscribe = new Subject();
  unsubscribe1 = new Subject();
  showNewsletterBox: boolean = true;
  staticpages: any;
  constructor(private ls: LocalStorageService, private categoriesService: CategoriesService, private swalService: SwalService, private modalService: BsModalService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getAllCat();
    this.initForm();
    this.getAllStaticPages();
    let url = window.location.href;
    this.categoriesService.toShownewsletterBox.subscribe((val) => {
      if (val) {
        this.showNewsletterBox = false;
      }
      else {
        this.showNewsletterBox = true;
      }
    })
  
    if(!url.includes("profile")){
        if (this.ls.getItem('currentUser')) {
      const userValue = this.ls.getItem('currentUser');
      console.log(userValue);
      
      if (userValue.newsletter_subscribed) {
        this.categoriesService.setnewletterSuscription(true);
        this.showNewsletterBox = false;
      }
      else {
        this.categoriesService.setnewletterSuscription(false);
        this.showNewsletterBox = true;
      }
    }
  }

  }
  getAllStaticPages() {
    this.categoriesService.getAllStaticPages({}).subscribe((res) => {
        if (res['status']) {
          const data = res['pages'];
          this.staticpages = data.filter((data) => data.state == 'static');
        }
    }, (err) => {
        if (err.error.message) {
            console.log("something went wrong");
        }
    });
}
  private initForm(): void {
    this.subscriptionForm = new FormGroup({
      'email': new FormControl('', [Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)]),
    });
  }

  getAllCat() {
    this.categoriesService.getAll({}).pipe(takeUntil(this.unsubscribe)).subscribe(res => {
      if (res['status']) {
        this.catList = res['categories'];
      }
    })
  }
  signUpForUs(template: TemplateRef<any>) {
    this.subscriptionFormSubmitted = true;
    if (this.subscriptionForm.invalid) {
      return;
    }
    let payload = {
      email: this.subscriptionForm.value.email
    }
    this.categoriesService.newsletterSubscription(payload).pipe(takeUntil(this.unsubscribe1)).subscribe(res => {
      if (res['status']) {
        const rememberVariable = this.ls.isRememberMe();
        this.ls.setItem('token', res['user'].token, rememberVariable);
        this.ls.setItem('currentUser', JSON.stringify(res['user']), rememberVariable);
        this.categoriesService.setnewletterSuscription(true);
        this.showNewsletterBox = false;
        this.tempSubscriptionMessage = "Thank you for your subscription. An e-mail has been sent to your address. Please click the provided link to prove you are the owner of this e-mail address and confirm subscription. If you can't find our e-mail please check your spam folder."
        this.bsModalRef = this.modalService.show(template, {
          animated: true,
          backdrop: 'static',
          class: 'my-modal'
        });
      }
      else {
        this.toastr.error(res['message'], '');
      }
    }, err => {
      this.toastr.error(err.error.message, '');
    })

  }
  get formControls() {
    return this.subscriptionForm.controls;
  }
  closePopUp() {
    this.bsModalRef.hide();
    this.subscriptionForm.reset();
    this.subscriptionFormSubmitted = false;
  }
  ngOnDestroy() {
    this.unsubscribe.unsubscribe();
    this.unsubscribe1.unsubscribe();
  }

}
