<section class="form-pages pt-120 bg-center discover-app-page pb-280">
    <ngx-spinner></ngx-spinner>
    <div class="container">
        <div class="row align-items-center main-row">
            <div class="col-md-12 form-right-content">
                <div class="mw-100">
                    <div class="static-banner relative ml-0 mr-0 mt-2">
                        <img src="assets/img/discover-app/discoverapp-banner.jpg" alt="" class="w-100">
                        <div class="over-content">
                            <h2>Discover Apps</h2>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor </p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row cate-option theStartContent">
            <div class="carousel art mb-0 carousel-inner">
                <ngx-slick class="carousel" #focusCategory1="slick-modal" [config]="slideConfigArticles">
                    <a ngxSlickItem *ngFor="let cat of catList" [ngClass]="((cat._id).toString() == activeCat.toString()) ? 'active' : ''" class="slide" href="javascript:;" [routerLink]="['/discover-app']" [queryParams]="{id: cat._id}">
                        <div class="form-group text-center cat_item">
                            <div class="cate-img">



                                <img src="{{imgUrl}}{{cat.image}}" alt="">
                            </div>
                            <h4>{{cat.name}}</h4>
                        </div>
                    </a>
                </ngx-slick>
            </div>
            <!-- (click)="categorySelected(cat._id)" -->
            <!-- <div class="col-md-cate cate-wrap" *ngFor="let cat of catList" [ngClass]="((cat._id).toString() == activeCat.toString()) ? 'active' : ''">
                <div class="form-group text-center cat_item" (click)="categorySelected(cat._id)">
                    <div class="cate-img">
                        <img *ngIf="cat.name == 'Agriculture'" src="assets/img/appcategory-icon1.png">
                        <img *ngIf="cat.name == 'Health' || cat.name == 'Safety'"
                            src="assets/img/appcategory-icon2.png">
                        <img *ngIf="cat.name == 'Education'" src="assets/img/appcategory-icon3.png">
                        <img *ngIf="cat.name == 'Governance'" src="assets/img/appcategory-icon4.png">
                        <img *ngIf="cat.name == 'Disability'" src="assets/img/appcategory-icon5.png">
                    </div>
                    <h4>{{cat.name}}</h4>
                </div>
            </div>       -->
            <!-- cate-wrap end -->
        </div>
        <!-- cate-option end -->

        <div class="category-heading">
            <h2>All Apps</h2>
        </div>

        <div *ngIf="allAppsShow" class="row category-listing">
            <div class="col-md-3 category-sidebar hide-on-init" animateOnScroll animationName="animated fadeIn">
                <div class="wrap h-100">
                    <div class="row">
                        <div class="col-lg-12">
                            <button (click)="resetOption()" title="Reset Filters" style="float:right;" class="backbtn">Reset Filters
                                 <i class="fa fa-filter text-dark"></i>
                                </button>
                        </div>
                    </div>

                    <div class="single category">
                        <h3 class="side-title relative">
                            <a>Sub Categories 
                                <!-- <i class="fa fa-caret-up" aria-hidden="true"></i> -->
                            </a>
                        </h3>
                        <div *ngIf="subCatList && subCatList.length else noSubCategories">
                            <div class="custom-control custom-radio" *ngFor="let sbCat of subCatList; let aIndex = index">
                                <input type="radio" [checked]="(currentSubCategory) == (sbCat._id)" name="defaultExampleRadios" class="custom-control-input" id="{{aIndex}}" [value]="sbCat._id" (change)="subCatSelected($event.target.value)">
                                <label class="custom-control-label" for="{{aIndex}}">{{sbCat.name}}</label>
                            </div>
                        </div>
                        <ng-template #noSubCategories>
                            <p> No Sub Categories available</p>
                        </ng-template>
                        <!-- Default unchecked -->
                        <!-- <div class="custom-control custom-radio">
                      <input type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios">
                      <label class="custom-control-label" for="defaultUnchecked">Agri technology</label>
                    </div> -->

                        <!-- Default checked -->
                        <!--   <div class="custom-control custom-radio">
                      <input type="radio" class="custom-control-input" id="defaultChecked" name="defaultExampleRadios" checked>
                      <label class="custom-control-label" for="defaultChecked">Animal husbandry</label>
                    </div>
                     -->

                    </div>
                    <div class="single category">
                        <h3 class="side-title relative">
                            <a>App Type 
                                <!-- <i class="fa fa-caret-up" aria-hidden="true"></i> -->
                            </a>
                        </h3>
                        <div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [value]="'Efficiency'" name="app-type" [checked]="(selectedAppType) == 'Efficiency'" (change)="appTypeSelected($event.target.value)" class="custom-control-input" id="n1">
                                <label class="custom-control-label" for="n1">Efficiency</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [value]="'F-Droid'" name="app-type" [checked]="(selectedAppType) == 'F-Droid'" (change)="appTypeSelected($event.target.value)" class="custom-control-input" id="n2">
                                <label class="custom-control-label" for="n2">F-Droid</label>
                            </div>

                            <div class="custom-control custom-radio">
                                <input type="radio" [value]="'iOS'" name="app-type" [checked]="(selectedAppType) == 'iOS'" (change)="appTypeSelected($event.target.value)" class="custom-control-input" id="n3">
                                <label class="custom-control-label" for="n3">IOS</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [value]="'Knowledge'" name="app-type" [checked]="(selectedAppType) == 'Knowledge'" (change)="appTypeSelected($event.target.value)" class="custom-control-input" id="n4">
                                <label class="custom-control-label" for="n4">Knowledge</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [value]="'Android'" name="app-type" [checked]="(selectedAppType) == 'Android'" (change)="appTypeSelected($event.target.value)" class="custom-control-input" id="n5">
                                <label class="custom-control-label" for="n5">Android</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [value]="'Learning'" name="app-type" [checked]="(selectedAppType) == 'Learning'" (change)="appTypeSelected($event.target.value)" class="custom-control-input" id="n6">
                                <label class="custom-control-label" for="n6">Learning</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [value]="'Market Place'" name="app-type" [checked]="(selectedAppType) == 'Market Place'" (change)="appTypeSelected($event.target.value)" class="custom-control-input" id="n7">
                                <label class="custom-control-label" for="n7">Market Place</label>
                            </div>
                        </div>
                    </div>
                    <div class="single category">
                        <h3 class="side-title relative">
                            <a>Names 
                                <!-- <i class="fa fa-caret-up" aria-hidden="true"> </i> -->
                            </a>
                        </h3>
                        <div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [value]="'asc'" name="sortType" (change)="filterDiscoverApps($event,'name')" class="custom-control-input" id="st1">
                                <label class="custom-control-label" for="st1">A to Z</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [value]="'dsc'" name="sortType" (change)="filterDiscoverApps($event,'name')" class="custom-control-input" id="st2">
                                <label class="custom-control-label" for="st2">Z to A</label>
                            </div>
                        </div>
                    </div>
                    <div class="single category">
                        <h3 class="side-title relative">
                            <a>Ratings 
                                <!-- <i class="fa fa-caret-up" aria-hidden="true"> </i> -->
                            </a>
                        </h3>
                        <div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [value]="'asc'" name="sortType" (change)="filterDiscoverApps($event,'rating')" class="custom-control-input" id="st3">
                                <label class="custom-control-label" for="st3">Low to High</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [value]="'dsc'" name="sortType" (change)="filterDiscoverApps($event,'rating')" class="custom-control-input" id="st4">
                                <label class="custom-control-label" for="st4">High to Low</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-9 category-content">
                <!-- <div class="row">
                        <select class="pull-right" (change)="filterDiscoverApps($event,'name')">
                            <option value="" selected>Names</option>
                            <option value="asc">A to Z</option>
                            <option value="dsc">Z to A</option>
                        </select>
                        <select class="pull-right" (change)="filterDiscoverApps($event,'rating')">
                            <option value="" selected>Ratings</option>
                            <option value="asc">Low to High</option>
                            <option value="dsc">High to Low</option>
                        </select>
                    </div> -->
                <div class="wrap">
                    <div class="row" *ngIf="appList?.length; else noApps">
                        <div class="col-md-4 col-6 category-listing-col text-center" *ngFor="let app of appList; let isOdd=odd">

                            <div class="wrap hide-on-init thumb-cat" (click)="appDetails(app._id)" animateOnScroll animationName="animated fadeIn">
                                <div class="app-logo"><img [src]="app.app_logo ? imgUrl+app.app_logo : dummyImg" alt="">
                                </div>
                                <h3 class="font22 font-bold mt-3 mb-3">{{app.name}}
                                </h3>
                                <div class="rating">
                                    <rating [(ngModel)]="app.rating" [readonly]="true"></rating>
                                    <!-- <img src="assets/img/app-rating.png" alt> -->
                                </div>
                                <!-- <div class="rating mb-3"><img src="assets/img/app-ratings.png"></div> -->
                                <h4 *ngIf="app.publisher"> <b>By {{app.publisher.name}}</b></h4>
                                <!-- <p [innerHTML]="app.app_summary"></p> -->
                            </div>
                        </div>
                    </div>
                    <ng-template #noApps>
                        <div class="row">
                            <div class="col-12 text-center">
                                <p><strong>No App Available!</strong></p>
                            </div>
                        </div>

                    </ng-template>
                    <div class="row pagination-row mt-5 mb-0" *ngIf="appList?.length > 0">
                        <div class="col-md-12 text-right">
                            <div class="pagination justify-content-end">
                                <div class="page-item">
                                    <pagination [boundaryLinks]="true" [itemsPerPage]="itemsPerPage" [totalItems]="totalItems" [(ngModel)]="page" (pageChanged)="pageChanged($event)" [customPreviousTemplate]="prevTemplate" [maxSize]="maxSize" [customNextTemplate]="nextTemplate" [customFirstTemplate]="firstTemplate"
                                        [customLastTemplate]="lastTemplate"></pagination>
                                    <ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
                                        <ng-container *ngIf="!disabled">
                                            Next
                                        </ng-container>
                                    </ng-template>
                                    <ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
                                        <ng-container *ngIf="!disabled">
                                            Previous
                                        </ng-container>
                                    </ng-template>

                                    <ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
                                        <ng-container *ngIf="!disabled">
                                            Last
                                        </ng-container>
                                    </ng-template>

                                    <ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
                                        <ng-container *ngIf="!disabled">
                                            First
                                        </ng-container>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- category-content -->

        <!-- <div *ngIf="!allAppsShow"  class="similar-app-slider row pb-280">
                  <div class="col-md-12"><h3>Similar apps</h3>
                        <div class="carousel art mb-0 carousel-inner">
                            <carousel [itemsPerSlide]="itemsPerSlide" [singleSlideOffset]="singleSlideOffset"
                                [isAnimated]="true" [interval]="cycleInterval" [noWrap]="noWrap">
                                <slide *ngFor="let app of appList">
                                     <div class="category-listing-col text-center">
                                        <div class="wrap h-100">
                                    <div class="app-logo">
                                        <img [src]="app.app_logo ? imgUrl+app.app_logo : dummyImg" alt="">
                                    </div>
                                    <h3 class="font22 font-bold mt-3 mb-3">{{app.name}}
                                    </h3>
                                    <div class="rating">
                                        <rating [(ngModel)]="app.rating" [readonly]="true"></rating>
                                    </div>
                                    <h4 *ngIf="app.publisher"> <b>By {{app.publisher.name}}</b></h4>
                                </div>
                            </div>
                                </slide>
                            </carousel>
                        </div>
                    </div>
                </div> -->
    </div>
</section>
<!-- End Hero -->