<!-- ======= Hero Section ======= -->
<section class="form-pages pt-120 pb-280 logins-comman">

    <div class="container">
        <div class="row align-items-center main-row">
            <div class="col-md-7 form-left-content">
                <h2 class="font-bold font42  animated slow fadeIn">Thank you for deciding <br> to create a <span
                        class="text-red">Social App Hub</span> <br> Account.
                </h2>
                <p class="font-20  animated slow fadeIn">You are warmly welcomed to our community. We invite you to
                    follow our social media feeds in addition to regularly visiting this website to stay informed about
                    the social good sector:
                </p>
            </div>
            <div class="col-md-5 form-right-content">

                <div class="form-wrap animated slow fadeIn">
                    <form novalidate [formGroup]="registerForm">
                        <div class="form-heading">
                            <h3 class="font30 form-color1">Sign Up!</h3>
                            <p class="form-color2">Create new Account</p>
                        </div>

                        <div class="row basic-info2">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <span class="outer-label">Mobile Number</span>
                                    <input type="tel" name="" class="form-control" maxlength="10"
                                        [ngClass]="((registerForm.controls['mobile'].errors?.required || registerForm.controls['mobile'].errors?.pattern ) && isSubmitting)? 'error-msg':''"
                                        minlength="10" formControlName='mobile' placeholder="Mobile Number"
                                        pattern="(^[0][1-9]+)|([1-9]\d*)" (keypress)="myNumberFunction($event)">

                                </div>
                            </div>
                        </div>
                        <!-- OTP Verification -->
                        <div class="row basic-info2" [hidden]="!step1registrationstarted">
                            <div class="form-wrap animated slow fadeIn">
                                <div class="form-heading">
                                    <h3 class="font30 form-color1">OTP Verification</h3>
                                    <!-- <p class="form-color2">Enter The OTP send to +91 {{userNumber}}</p> -->
                                </div>
                                <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="otpConfig">
                                </ng-otp-input>
                                <!-- <ng-container counter [counter]="setting.timer" (value)="onCounterChange($event)">
                                    <div class="mt-2"> -->
                                <!-- <button class="btn button-md btn-dark" *ngIf="counter === 0" (click)="ressendOtp()">
                                            Resend OTP
                                        </button> -->
                                <!-- <span *ngIf="counter != 0 ">Resend in {{ counter }} seconds.</span> -->
                                <!-- </div>
                                </ng-container> -->
                                <div class="row justify-content-center action-btn">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <span  [hidden]="displayResendOtpButton">Resend Otp in 
                                            <countdown #countdown [config]="config" (event)="handleEvent($event)"></countdown></span>
                                            <button type="submit" [hidden]="!displayResendOtpButton" (click)="resendOtp()"
                                                class="btn btn-primary rounded-pill w-100">Resend OTP</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div  [hidden]="!step1registrationCompleted">
                            <P> <i class="fa fa-check-circle"></i> Number Verified</P>
                        </div>
                        <!-- OTP Verification End-->
                        <div class="row basic-info2" [hidden]="!step1registrationCompleted">
                            <div class="col-md-12">
                                <div class="form-group password-icon">
                                    <span class="outer-label">Password</span>
                                    <input (keydown.space)="$event.preventDefault();" placeholder="Password" type="{{passwordVisibility ? 'text' : 'password'}}" name="password" [ngClass]="((registerForm.controls['password'].errors?.required || registerForm.controls['password'].errors?.minlength || registerForm.controls['password'].errors?.pattern) && isSubmitting)? 'error-msg':''"
                                        class="form-control pr-5" formControlName="password" autocomplete="off">
                                    <a href="javascript:;" class="icon"><img src="assets/img/eye.png" alt="view" (click)="passwordVisibility = !passwordVisibility" /></a>
                                    <!-- <div
                                        *ngIf="registerForm.controls['password'].errors && (registerForm.get('password').dirty || registerForm.get('password').touched || isSubmitting)">
                                        <small class="text-danger error-msg"
                                            *ngIf="registerForm.controls['password'].errors && registerForm.controls['password'].errors.required">*Password
                                            is
                                            required.</small>
                                        <small class="text-danger error-msg"
                                            *ngIf="registerForm.controls['password'].errors && registerForm.controls['password'].errors.pattern">
                                           Wrong pattern
                                        </small>
                                    </div> -->
                                </div>
                                <p class="note-quote"><i class="fa fa-info-circle" aria-hidden="true"></i>Password should contains a lowercase, a uppercase character and a digit.

                                </p>
                            </div>
                        </div>
                        <div class="row action-checkbtn-row">
                            <div class="col-md-7 acr-left pr-0">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" class="custom-control-input" id="defaultUnchecked">
                                        <label class="custom-control-label" for="defaultUnchecked">Notify me about Case
                                            Studies</label>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-5 acr-right">
                                <div class="form-group text-right">
                                    <a href="#" [routerLink]="['../' + APP_ROUTES.forgotPassword]">Forgot password?</a>
                                </div>
                            </div> -->
                        </div>
                        <!-- action-checkbtn-row end -->

                        <div class="row action-btn justify-content-center">
                            <div class="col-md-6 act-btn-left">
                                <div class="form-group">
                                    <button type="submit" [hidden]="step1registrationCompleted" (click)="step1Registration()"
                                        class="btn btn-primary rounded-pill w-100">Continue</button>
                                    <button type="submit" [hidden]="!step1registrationCompleted" (click)="doRegister($event)"
                                        class="btn btn-primary rounded-pill w-100">Sign Up</button>
                                </div>
                            </div>
                        </div>
                        <div class="row action-btn mt-2 justify-content-center">
                            <div class="col-md-12 text-center act-btn-right">
                                <div class="form-group">
                                    <a href="javascript:;" type="submit" [routerLink]="['../' + APP_ROUTES.login]">Existing Account ? Log in</a>
                                    <!-- <button class="btn rounded-pill btn-default w-100 grey-border"
                                        [routerLink]="['../' + APP_ROUTES.login]">Login</button> -->
                                </div>
                            </div>
                        </div>
                        <!-- action-btn end -->


                        <div class="row or-row">
                            <div class="col-md-12">
                                <p><span>Or</span></p>
                            </div>
                        </div>
                        <!--  or-row end -->

                        <div class="row social-action-btn">
                            <div class="col-md-12">
                                <a href="javascript:;" class="btn btn-default w-100 rounded-pill fb-btn"
                                    (click)="socialSignIn('facebook')"><i class="fa fa-facebook" aria-hidden="true"></i>
                                    Signup with facebook</a>
                                <a href="javascript:;" class="btn btn-default w-100 rounded-pill google-btn"
                                    (click)="socialSignIn('google')"><img src="assets/img/google-plus-icon.png"> Signup
                                    with Google Plus</a>
                            </div>
                        </div>
                        <!--  social-action-btn end -->

                    </form>
                </div>
            </div>
            <ng-template>

            </ng-template>


        </div>
    </div>

</section>
<!-- End Hero -->