import { LocalStorageService } from './../../../shared/storage/local-storage.service';
import { PreAuthService } from './../pre-auth.service';
import { ApiRoutes } from './../../../shared/routes/apiRoutes';
import { APP_ROUTES } from './../../../shared/routes/appRoutes';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-social-login-set-password',
  templateUrl: './social-login-set-password.component.html',
  styleUrls: ['./social-login-set-password.component.scss']
})
export class SocialLoginSetPasswordComponent implements OnInit {

  public APP_ROUTES = APP_ROUTES;
  public ChangePasswordForm: FormGroup;
  public isSubmitting: boolean = false;
  public isDisabled: boolean = false;
  public formSubmitSuccess: boolean = false;

  constructor(private router: Router,
    private service: PreAuthService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private localStorage : LocalStorageService) { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
    this.ChangePasswordForm = this.fb.group({
      newPassword: ['', [Validators.required, Validators.minLength(6),Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)]],
      confirmNewPassword: ['', [Validators.required, Validators.minLength(6), this.passwordMatcher.bind(this),Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)]]
    });
  }

  private passwordMatcher(control: FormControl): { [s: string]: boolean } {
    if (this.ChangePasswordForm && (control.value !== this.ChangePasswordForm.controls.newPassword.value)) {
      return { passwordNotMatch: true };
    }
    return null;
  }


  change(e: number): void {
    this.formSubmitSuccess = false;
    if (this.ChangePasswordForm.invalid) {
      this.isSubmitting = true;
      return;
    }
    this.isSubmitting = false;
    let payload = {
      password: this.ChangePasswordForm.value.newPassword
    };
    this.service.setSocialPassword(payload).subscribe((data: any) => {
      if (data.status === 200) {
          this.formSubmitSuccess = true;
          this.ChangePasswordForm.reset();
          this.localStorage.removeItem('password_state');
          setTimeout(() => {
            this.toastr.success(data.body.message, '');
          }, 1000);
          //New Code
          const rememberVariable = this.localStorage.isRememberMe();
        this.localStorage.setItem('token', data.body.user.token,rememberVariable);
        this.localStorage.setItem('currentUser', JSON.stringify(data.body.user), rememberVariable);
        //New Code Ends
          this.router.navigate([APP_ROUTES.profile]).then(() => {
            // for some async task
          });
      }
    }, err => {
      this.toastr.error(err.error.message, '');
      this.router.navigate([APP_ROUTES.login]).then(() => {
        // for some async task
      });
    });
  }

}
