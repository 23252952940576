import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators,FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-newsletter-subscriprion',
  templateUrl: './newsletter-subscriprion.component.html',
  styleUrls: ['./newsletter-subscriprion.component.scss']
})
export class NewsletterSubscriprionComponent implements OnInit {

  public newsLetterForm: FormGroup;

  Designations: any = ['Cxo', 'Developer', 'Designer', 'QA'];
  Roles: any = ['Admin', 'Project Management', 'General Management', 'Software Development', 'Ui Design'];

  constructor(private fb :FormBuilder) { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm(): void {
		this.newsLetterForm = this.fb.group({
			email: ['', [Validators.required, Validators.pattern(/^[a-z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,4}$/)]],
      phoneNo: ['', [Validators.required, Validators.maxLength(11), Validators.pattern(/^[0-9]*$/)]],
      company: ['', [Validators.required]],
      designation: ['', [Validators.required]],
      role: ['', [Validators.required]],
      firstName: ['', [Validators.required,Validators.maxLength(50)]],
		});
  }

  changeSelection(e, type:any) {
    console.log(e.value)
    if(type === 'designation')
    {
      this.newsLetterForm.get('designation').setValue(e.target.value, {
        onlySelf: true
      });
    }
    else if(type === 'role')
    {
      this.newsLetterForm.get('role').setValue(e.target.value, {
        onlySelf: true
      });
    }

  }

}
