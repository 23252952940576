import { environment } from 'src/environments/environment';
import { ApiRoutes } from './../../../shared/routes/apiRoutes';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class KnowledgeSectionService {

  constructor(private http: HttpClient) { }

  public getSliderImages(): Observable<any> {
    const url: string = ApiRoutes.caseStudySliderImages;
		return this.http.get(url, {observe: 'response'});
  }

  public getInterviewSliderImages(): Observable<any> {
    const url: string = ApiRoutes.interviewSliderImages;
		return this.http.get(url, {observe: 'response'});
  }

  public getArticlesSliderImages(): Observable<any> {
    const url: string = ApiRoutes.articlesSliderImages;
		return this.http.get(url, {observe: 'response'});
  }

  public getNgoSliderImages(): Observable<any> {
    const url: string = ApiRoutes.ngoSliderImages;
		return this.http.get(url, {observe: 'response'});
  }

  public getCaseStudy(page?:any): Observable<any> {
		const url: string = ApiRoutes.caseStudy;
		if(page){
      return this.http.get(url, {
        params: {
          page: page,
          pageSize: "6",
          status: 'published'
        },
        observe: 'response'
      });
    }
    else{
      return this.http.get(url, {observe: 'response'});
    }
  }

  public getInterview(page?:any): Observable<any> {
		const url: string = ApiRoutes.interview;
		if(page){
      return this.http.get(url, {
        params: {
          page: page,
          pageSize: "5",
          status: 'published'
        },
        observe: 'response'
      });
    }
    else{
      return this.http.get(url, {observe: 'response'});
    }
  }

  public getNews(page?:any): Observable<any> {
    const url: string = ApiRoutes.news;
    if(page){
      return this.http.get(url, {
        params: {
          page: page,
          pageSize: "6", 
          status: 'published'
        },
        observe: 'response'
      });
    }
    else{
		return this.http.get(url, {observe: 'response'});}
  }

  public clickNews(id?:any): Observable<any> {
    const url: string = ApiRoutes.clicknews + `?id=${id}`;
		return this.http.get(url, {observe: 'response'});
  }

  public getArticles(page?:any): Observable<any> {
    const url: string = ApiRoutes.articles;
    if(page){
      return this.http.get(url, {
        params: {
          page: page,
          pageSize: "8",
          status: 'published'
        },
        observe: 'response'
      });
    }
    else{
      return this.http.get(url, {observe: 'response'});
    }

  }

  public getNgo(page:any): Observable<any> {
		const url: string = ApiRoutes.ngo;
		return this.http.get(url, {
      params: {
        page: page,
        pageSize: "5",
        status: 'published'
      },
      observe: 'response'
    });
  }

  public getCaseStudyDetails(id : any,page,pageSize): Observable<any> {
		const url: string = ApiRoutes.caseStudyDetails + '/' + id;
    return this.http.get(url, {
       observe: 'response',
       params: {
        page: page,
        pageSize: pageSize
       }
   });
  }

  public getSimilarCaseStudyDetails(id : any,page,pageSize): Observable<any> {
		const url: string = ApiRoutes.caseSimilarStudyDetails;
    return this.http.get(url, {
       observe: 'response',
       params: {
        id,
        page,
        pageSize
       }
   });
  }

  public getInterviewDetails(id : any,page,pageSize): Observable<any> {
		const url: string = ApiRoutes.interviewDetails + '/' + id;
    return this.http.get(url, {
      observe: 'response',
      params: {
        page: page,
        pageSize: pageSize
       }
   });
  }
  public getSimilarInterviewDetails(id : any,page,pageSize): Observable<any> {
		const url: string = ApiRoutes.similarInterviewDetails;
    return this.http.get(url, {
       observe: 'response',
       params: {
        id,
        page,
        pageSize
       }
   });
  }

  public getArticlesDetails(id : any,page,pageSize): Observable<any> {
		const url: string = ApiRoutes.articleDetails + '/' + id;
		return this.http.get(url, {
       observe: 'response',
       params: {
        page: page,
        pageSize: pageSize
       }
       });
  }
  public getSimilarArticlesDetails(id : any,page,pageSize): Observable<any> {
		const url: string = ApiRoutes.similarArticlesDetails;
    return this.http.get(url, {
       observe: 'response',
       params: {
        id,
        page,
        pageSize
       }
   });
  }

  public getHomeSliderImages(): Observable<any> {
		const url: string = ApiRoutes.homeSlider;
		return this.http.get(url, { observe: 'response' });
  }

  public getApprovedApps(page): Observable<any> {
		const url: string = ApiRoutes.approvedApps;
		return this.http.get(url, {
            params: {
                page
            }
        });
  }

}
