<!-- <div class="container mt-100">
	<div class="row align-items-center flex-column">
		<h2 class="heading-img-center text-center">Change password</h2>
		<div class="card col-10 mt-4 mb-5">
			<div class="card-body">
				<form novalidate [formGroup]="ChangePasswordForm">
					<div class="row pt-4">
						<div class="col-md-12">
							<div class="form-group password-icon">
								<label>New password</label>
								<input type="password" class="form-control" formControlName="newPassword" autocomplete="off"
									placeholder="Enter Password" />
							</div>
							<ng-container *ngIf="ChangePasswordForm.controls['newPassword'].errors && (ChangePasswordForm.get('newPassword').dirty || ChangePasswordForm.get('newPassword').touched || isSubmitting)">
								<small class="text-danger error-msg" [hidden]="!ChangePasswordForm.controls['newPassword'].errors.required">
									*Password is required.
								</small>
								<small class="text-danger error-msg" [hidden]="!ChangePasswordForm.controls['newPassword'].errors.minlength">
									*Password must be minimum 6 character long.
								</small>
							</ng-container>
						</div>


						<div class="col-md-12">
							<div class="form-group password-icon">
								<label>Confirm password</label>
								<input type="password" class="form-control" formControlName="confirmNewPassword" autocomplete="off"
									placeholder="Confirm Password" />
							</div>
							<ng-container *ngIf="ChangePasswordForm.controls['confirmNewPassword'].errors && (ChangePasswordForm.get('confirmNewPassword').dirty || ChangePasswordForm.get('confirmNewPassword').touched || isSubmitting)">
								<small class="text-danger error-msg" [hidden]="!ChangePasswordForm.controls['confirmNewPassword'].errors.required">
									*Password is required.
								</small>
								<small class="text-danger error-msg" [hidden]="!ChangePasswordForm.controls['confirmNewPassword'].errors.minlength">
									*Password must be minimum 6 character long.
								</small>
								<small class="text-danger error-msg" [hidden]="ChangePasswordForm.errors?.passwordNotMatch">
									Passwords do not match
								</small>
							</ng-container>
						</div>
						<div *ngIf="formSubmitSuccess" class="col-12 d-flex justify-content-center align-items-center">
							<p class="m-0 text-success">
								Password changed, please <a href="javascript:;" [routerLink]="['../' + APP_ROUTES.login]">Login</a>
							</p>
						</div>
						<div class="col-12 d-flex justify-content-center align-items-center mt-4 mb-4 flex-column">
						
							<button (click)="change($event)">
								Save Password
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>
 -->




<section class="form-pages pt-120 pb-280 logins-comman">
	<div class="container">
		<div class="row align-items-center main-row">
			<div class="col-md-7 form-left-content">
				<h2 class="font-bold font42">Thank you for deciding <br>
					to create a <span class="text-red">Social App Hub</span> <br>
					Account.</h2>
				<p class="font-20">You are warmly welcomed to our community. We invite you to follow our social media
					feeds in addition to regularly visiting this website to stay informed about the social good sector:
				</p>
			</div>
			<div class="col-md-5 form-right-content">

				<div class="form-wrap">
					<form novalidate [formGroup]="ChangePasswordForm">
						<div class="form-heading">
							<h3 class="font30 form-color1">Change Password!</h3>
						</div>
						<div class="row pt-4">
							<div class="col-md-12">
								<div class="form-group password-icon mb-4">
									<span class="outer-label">New password</span>
									<input type="password" class="form-control" formControlName="newPassword" autocomplete="off"
									[ngClass]="((ChangePasswordForm.controls['newPassword'].errors?.required || 
									ChangePasswordForm.controls['newPassword'].errors?.minlength || ChangePasswordForm.controls['newPassword'].errors?.pattern) && isSubmitting)? 'error-msg':''" 

										placeholder="Enter Password" />
								</div>
								<!-- <ng-container *ngIf="ChangePasswordForm.controls['newPassword'].errors && (ChangePasswordForm.get('newPassword').dirty || ChangePasswordForm.get('newPassword').touched || isSubmitting)">
									<small class="text-danger error-msg" [hidden]="!ChangePasswordForm.controls['newPassword'].errors.required">
										*Password is required.
									</small>
									<small class="text-danger error-msg" [hidden]="!ChangePasswordForm.controls['newPassword'].errors.minlength">
										*Password must be minimum 6 character long.
									</small>
								</ng-container> -->
							</div>
	
	
							<div class="col-md-12">
								<div class="form-group password-icon">
									<span class="outer-label">Confirm password</span>
									<input type="password" class="form-control" formControlName="confirmNewPassword" autocomplete="off"
									[ngClass]="((ChangePasswordForm.controls['confirmNewPassword'].errors?.required || ChangePasswordForm.controls['confirmNewPassword'].errors?.minlength || ChangePasswordForm.controls['confirmNewPassword'].errors?.passwordNotMatch || 
									ChangePasswordForm.controls['confirmNewPassword'].errors?.pattern) && isSubmitting)? 'error-msg':''" 
										placeholder="Confirm Password" />
								</div>
								<ng-container *ngIf="ChangePasswordForm.controls['confirmNewPassword'].errors?.passwordNotMatch && isSubmitting">
									<small class="text-danger error-msg" [hidden]="ChangePasswordForm.errors?.passwordNotMatch">
										Passwords do not match
									</small>
								</ng-container>
								<!-- <ng-container *ngIf="ChangePasswordForm.controls['confirmNewPassword'].errors && (ChangePasswordForm.get('confirmNewPassword').dirty || ChangePasswordForm.get('confirmNewPassword').touched || isSubmitting)">
									<small class="text-danger error-msg" [hidden]="!ChangePasswordForm.controls['confirmNewPassword'].errors.required">
										*Password is required.
									</small>
									<small class="text-danger error-msg" [hidden]="!ChangePasswordForm.controls['confirmNewPassword'].errors.minlength">
										*Password must be minimum 6 character long.
									</small>
									<small class="text-danger error-msg" [hidden]="ChangePasswordForm.errors?.passwordNotMatch">
										Passwords do not match
									</small>
								</ng-container> -->
							</div>

							<div class="col-12"><p _ngcontent-ghn-c4="" class="note-quote"><i _ngcontent-ghn-c4="" aria-hidden="true" class="fa fa-info-circle"></i>Password should contains a lowercase, a uppercase character and a digit. </p></div>

							<div *ngIf="formSubmitSuccess" class="col-12 d-flex justify-content-center align-items-center">
								<p class="m-0 text-success">
									Password changed, please <a href="javascript:;" [routerLink]="['../' + APP_ROUTES.login]">Login</a>
								</p>
							</div>
							<div class="col-12 d-flex justify-content-center align-items-center mt-4 mb-4 flex-column">
								<!-- <app-form-btn-submit [config]="submitBtnConfig" (clickHandler)="change($event)">
								</app-form-btn-submit> -->
								<button (click)="change($event)" class="btn rounded-pill btn-primary grey-border pl-3 pr-3">
									Save Password
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>

		</div>
	</div>

</section>