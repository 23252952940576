import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageScrollService } from 'ngx-page-scroll-core';
import { ToastrService } from 'ngx-toastr';
import { ApiRoutes } from 'src/app/shared/routes/apiRoutes';
import { CategoriesService } from 'src/app/shared/services/header.service';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {

  totalNewsLetters: number = 0;
  newsLetters: any = []
  imageBasePath = environment.imgUrl;
  itemsPerPage = 6;
  page: number = 1;
  maxSize = 10;
  subscription1 = new Subject();
  categoryList =[];
  selectedCat:any;
  searchText ='';
  searchMonth= '';
  filterCategory:any;

  constructor(private service: CategoriesService,
    private toastr: ToastrService,
    private route: Router,
    private pageScrollService: PageScrollService,
    private spinner: NgxSpinnerService,
    @Inject(DOCUMENT) private document: any,
  ) { }

  previewNewsLetter(id: any) {
    this.route.navigate(['newsletter/' + id])
  }

  pageChanged(event: any): void {
    this.page = event.page;
    this.pageScrolling('#scrollingToTop')
    this.loadPage();
  }
  showPagination: boolean = false

  loadPage(): void {
    this.spinner.show();
    let data:any ={};
    data.page= this.page;
    data.pageLength= "6";
    if(this.searchText != ''){
      data.searchText = this.searchText
    }
    if(this.filterCategory){
      data.filterCategory = this.filterCategory
    }
    if(this.searchMonth){
      data.searchMonth = this.searchMonth
    }
    this.service.getAllNewsLetter(data).subscribe((data: any) => {
      this.spinner.hide();
      if (data['status'] == true) {
        this.showPagination = true;
        this.newsLetters = data.newsletters;
        this.newsLetters.map(data => {
          if (data.month <= 9) {
            data.month = '0' + data.month + "-" + data.year
          } else {
            data.month = data.month + "-" + data.year
          }
        })
        this.totalNewsLetters = data.newsletters_count;
        this.showPagination = data.newsletters_count > 6 ? true : false;
      }
    }, err => {
      this.spinner.hide();
      this.toastr.error(err.error.message, '');
    });
  }

  pageScrolling(nameOfClass) {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: nameOfClass
    });
  }

  ngOnInit(): void {
    this.loadPage();
    this.getAllCat();
  }

  getAllCat() {
    this.spinner.show();
    this.service.getAllCatSubCat({}).pipe(takeUntil(this.subscription1)).subscribe(res => {
      this.spinner.hide();
      if (res['status']) {
        this.categoryList = res['categories'];
      }
    }, err => {
      this.spinner.hide();
      this.toastr.error(err.error.message, '');
    })
  }

  ngOnDestroy(){
    this.subscription1.unsubscribe();
  }

  selectFilter(event,type){
    if(type == 'months'){
      this.searchMonth = event.target.value;
    }
    if(type == 'category'){
      this.filterCategory = event.target.value;
    }
    if(type == 'search'){
      this.searchText = event; 
    }
    this.loadPage();
  }
}
