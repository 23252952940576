import { Component, OnInit, ViewChild } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CategoriesService } from '../../shared/services/header.service';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer, Title, SafeResourceUrl  } from '@angular/platform-browser';
import encodeUrl from '../../shared/sharer';
import { GoogleAnalyticsService } from 'src/app/shared/services/google-analytics.service';
// import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
    selector: 'app-discover-app-details',
    templateUrl: './discover-app-details.component.html',
    styleUrls: ['./discover-app-details.component.scss'],
})
export class DiscoverAppDetailsComponent implements OnInit {
    @ViewChild('focusCategory', { static: true }) focusCategory: any;
    // @ViewChild('appTypeChange') appTypeChange: ElementRef<HTMLElement>;
    itemsPerSlide = 3;
    itemsPerSlideFeatureApp = 3;
    cycleInterval = 3000;
    safeSrc: SafeResourceUrl;
    issafeSrc:boolean = true
    // check  = '<iframe width="200px" height="200px" src="https://www.youtube.com/embed/BGzPuIDSx5A"></iframe>'
    // check2 = 'https://www.youtube.com/embed/BGzPuIDSx5A'
    singleSlideOffset = true;
    noWrap = !true;
    activeCat: any = '';
    catList: any = [];
    subCatList: any = [];
    appList: any = [];
    filters: any = {};
    imgUrl: any;
    dummyImg = 'assets/img/discover-app/apps-logo.png';
    dummy = 'assets/img/discover-app/app-slider.jpg';
    appInfo: any = {};
    selectedSubCat: any;
    currentAppId: boolean = true;
    currentSubCategory: any;
    selectedAppType = '';
    screenshots = [];
    previouslySelectedSubCategory = '';
    previouslySelectedAppType = '';
    currentUrl: string;

    dummyImages = [
        'assets/img/app-screen.png',
        'assets/img/app-screen2.png',
        'assets/img/app-screen.png',
        'assets/img/app-screen2.png',
    ];

    // slideConfig = {
    //   dots: false,
    //   infinite: true,
    //   centerMode: true,
    //   slidesToShow: 3,
    //   slidesToScroll: 2
    // }
    slideConfig = {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,
        arrows: true,
        infinite: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ],
    };
    slideConfigArticles = {
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        centerMode: true,
        dots: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ],
    };
    slideConfigSimilarApps = {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: false,
        arrows: true,
        infinite: true,
        centerMode: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ],
    };
    subscription = new Subject();
    subscription1 = new Subject();
    subscription2 = new Subject();
    sharingUrl = environment.sharingUrl;
    socialShareUrl = '';
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private spinner: NgxSpinnerService,
        private categoriesService: CategoriesService,
        private sanitized: DomSanitizer,
        private titleService: Title,
        private titlecasePipe: TitleCasePipe,
        private gaService: GoogleAnalyticsService
    ) {
        this.imgUrl = environment.imgUrl;
        this.currentUrl = window.location.href;
    }

    ngOnInit(): void {
         this.route.queryParams.subscribe((param) => {
            if (param.subCat) {
                this.previouslySelectedSubCategory = param.subCat;
            }
            if (param.app_type) {
                this.previouslySelectedAppType = param.app_type;
            }
            // if(param.app_type){
            //     this.selectedAppType = param.app_type;
            // }
            if (param.id && param.appId) {
                this.currentAppId = param.appId;
                this.activeCat = param.id;
                this.filters = {
                    category_id: this.activeCat,
                };
                this.getAllCat();
                this.getAppDetail();
                this.getApps();
            } else return;

            // if (param.id) {
            //   this.activeCat = param.id
            //   this.filters = {
            //     category_id: this.activeCat
            //   }
            //   this.getAllCat()
            //   // this.getApps();
            // } else return
        });
    }

    getApps() {
        // this.spinner.show();
        this.categoriesService
            .getAllApps(this.filters, '1')
            .pipe(takeUntil(this.subscription))
            .subscribe((res) => {
                if (res['status']) {
                    let allApps = res['applications'];
                    let similarAppList = allApps.filter((i) => {
                        return (
                            this.currentAppId.toString() != i['_id'].toString()
                        );
                    });
                    this.appList = similarAppList;
                    // this.spinner.hide();
                }
            });
    }

    getAllCat() {
        this.categoriesService
            .getAllCatSubCat({})
            .pipe(takeUntil(this.subscription1))
            .subscribe((res) => {
                if (res['status']) {
                    this.catList = res['categories'];
                    let active = this.catList.filter(
                        (c) => c._id == this.activeCat
                    );
                    let indexOfSelectedCat = this.catList.findIndex(
                        (_i) => _i._id == this.activeCat
                    );
                    setTimeout(() => {
                        this.focusCategory.slickGoTo(indexOfSelectedCat);
                    }, 10);
                    this.subCatList = active[0]['subcategories'];
                }
            });
    }

    subCatSelected(_id) {
        if (_id != this.currentSubCategory._id) {
            this.router.navigate(['/discover-app'], {
                queryParams: { id: this.activeCat, subCat: _id },
            });
        }
        // this.selectedSubCat = _id;
        // delete this.filters['subcategory_id']
        // this.filters = Object.assign({
        //   subcategory_id: this.selectedSubCat
        // }, this.filters)
        // this.getApps();
    }

    categorySelected(id) {
        this.router.navigate(['/discover-app'], { queryParams: { id: id } });
        // this.filters = {
        //   category_id: id
        // }
        // this.activeCat = id;
        // this.getAllCat();
        // this.getApps();
    }

    // appDetails(id) {
    //   // this.router.navigate(['/discover-app-details/'+id])
    //   this.spinner.show();
    //   let active = this.appList.filter(c => c._id === id);
    //   this.appInfo = active[0];

    //   this.spinner.hide();
    // }
    getAppDetail() {
        this.categoriesService
            .getAppDetails({}, this.currentAppId)
            .pipe(takeUntil(this.subscription2))
            .subscribe((data) => {
                if (data['status']) {
                    this.appInfo = data['application'];
                    let result = this.appInfo.app_summary.indexOf("src=");
                     if(result == -1){
                        this.issafeSrc = false
                    }
                    if(result != -1){
                        
                        console.log("----------------------------------------------inside")
                  
                        let iframeStartIndex = this.appInfo.app_summary.indexOf("<iframe");
                        let iframeEndIndex = this.appInfo.app_summary.indexOf("</iframe>")+9;
                        let otherContent = this.appInfo.app_summary.slice(0,iframeStartIndex) + this.appInfo.app_summary.slice(iframeEndIndex,this.appInfo.app_summary.length)
                       
                        let index =this.appInfo.app_summary.slice(result+5)
                        let url = index.slice(0,index.indexOf('"'))
                        url = url.replace('/watch?v=', '/embed/')
                        this.appInfo.app_summary = otherContent
                        this.appInfo.htmlcontent = otherContent
                        this.safeSrc =  this.sanitized.bypassSecurityTrustResourceUrl(url);
                        document.getElementById("htmlcontent").click()
                    }
                    console.log("htmlContent========================",this.appInfo)
                    
                    this.socialShareUrl = this.sharingUrl +'/application/'+this.appInfo._id;
                    // this.meta.addTags([
                    //   { name: 'twitter:description', content: this.appInfo.app_summary },
                    //   { name: 'twitter:title', content: this.appInfo.name },
                    //   { name: 'twitter:image', content: this.imgUrl + this.appInfo.app_logo },
                    //   { name: "twitter:card", content:'summary_large_image' },
                    //   { name: "twitter:url", content: this.currentUrl },
                    //   { property: "og:title", content: this.appInfo.name },
                    //   { property: "og:description", content: this.appInfo.app_summary },
                    //   { property: "og:image:url", content: this.imgUrl + this.appInfo.app_logo },
                    //   { property: "og:image", content: this.imgUrl + this.appInfo.app_logo },
                    //   {property:"og:type" ,content:"article"},
                    //   {property:"og:url", content:this.currentUrl}
                    // ])
                    this.titleService.setTitle(
                        this.titlecasePipe.transform(data['application'].name) +
                        ' | Social App Hub'
                    );
                    this.gaService.eventEmitter(
                        'application',
                        this.appInfo['category'][0]['name'],
                        data['application'].name
                    );
                    // this.multiplyScreenshots(this.appInfo.screenshots);
                    this.screenshots = this.appInfo.screenshots;
                    this.currentSubCategory = this.appInfo['category'][0][
                        'subcategory'
                    ][0];
                }
            });
    }


    multiplyScreenshots(screenshots) {
        if (screenshots.length == 3 || screenshots.length == 4) {
            this.screenshots = screenshots.concat(screenshots);
        } else {
            this.screenshots = screenshots;
        }
    }

    backButton() {
        //   previouslySelectedSubCategory = '';
        // previouslySelectedAppType = '';
        if (
            this.previouslySelectedSubCategory != '' &&
            this.previouslySelectedAppType != ''
        ) {
            this.router.navigate(['/discover-app'], {
                queryParams: {
                    id: this.activeCat,
                    app_type: this.previouslySelectedAppType,
                    subCat: this.previouslySelectedSubCategory,
                },
            });
        } else if (
            this.previouslySelectedSubCategory &&
            this.previouslySelectedAppType == ''
        ) {
            this.router.navigate(['/discover-app'], {
                queryParams: {
                    id: this.activeCat,
                    subCat: this.previouslySelectedSubCategory,
                },
            });
        } else if (
            this.previouslySelectedSubCategory == '' &&
            this.previouslySelectedAppType
        ) {
            this.router.navigate(['/discover-app'], {
                queryParams: {
                    id: this.activeCat,
                    app_type: this.previouslySelectedAppType,
                },
            });
        } else {
            this.router.navigate(['/discover-app'], {
                queryParams: { id: this.activeCat },
            });
        }

        //  if(this.currentSubCategory){
        //   this.router.navigate(['/discover-app'] , { queryParams: { id: this.activeCat,subCat:this.currentSubCategory } })
        // }
        // else if(!this.currentSubCategory){
        // }
        // if(this.currentSubCategory && !this.selectedAppType){
        //   this.router.navigate(['/discover-app'] , { queryParams: { id: this.activeCat,subCat:this.currentSubCategory } })
        // }
        // else if(this.currentSubCategory && this.selectedAppType){
        //   this.router.navigate(['/discover-app'] , { queryParams: { id: this.activeCat,subCat:this.currentSubCategory,app_type:this.selectedAppType } })
        // }
        // else if(!this.currentSubCategory && this.selectedAppType){
        //   this.router.navigate(['/discover-app'] , { queryParams: { id: this.activeCat,app_type:this.selectedAppType } })
        // }
        // else if(!this.currentSubCategory && !this.selectedAppType){
        //   this.router.navigate(['/discover-app'] , { queryParams: { id: this.activeCat } })
        // }
    }
    appTypeSelected(name) {
        // let el: HTMLElement = this.appTypeChange.nativeElement;
        // el.click();
        if (this.currentSubCategory._id) {
            this.router.navigate(['/discover-app'], {
                queryParams: {
                    id: this.activeCat,
                    subCat: this.currentSubCategory._id,
                },
            });
        } else {
            this.router.navigate(['/discover-app'], {
                queryParams: { id: this.activeCat },
            });
        }
    }
    detailOfApp(app) {
        this.gotoTop();
        this.router.navigate(['/discover-app-detail'], {
            queryParams: { id: this.activeCat, appId: app._id },
        });
    }
    // Function for smooth scrolling
    gotoTop() {
        window.scroll({
            top: 0,
            // left: 0,
            behavior: 'smooth',
        });
    }
    resetOption() {
        this.router.navigate(['/discover-app'], {
            queryParams: { id: this.activeCat },
        });
    }

    ngOnDestroy() {
        this.subscription1.unsubscribe();
        this.subscription2.unsubscribe();
        this.subscription.unsubscribe();
    }

    transform(value) {
        if (value) {
            return this.sanitized.bypassSecurityTrustHtml(value);
        }
    }
    tagClickRedirection(data) {
        this.router.navigate(['/search'], {
            queryParams: { query: data },
        });
    }

    encodeSocialURI(input) {
        return encodeURIComponent(input);
    }
}
