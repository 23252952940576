import { LocalStorageService } from './../../shared/storage/local-storage.service';
import { ApiRoutes } from './../../shared/routes/apiRoutes';
import { APP_ROUTES } from './../../shared/routes/appRoutes';
import { LoginPayload,RegisterPayload } from './../../shared/interfaces/auth.interface';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs'
// import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from '@angular/router';

// import { DataService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class AuthorisationService {

  private authToken: string;
	private authState: BehaviorSubject<boolean>;
	private initialData: string[] = ['token'];

	constructor(private http: HttpClient, private router: Router, private ls: LocalStorageService ) {
		this.authState = new BehaviorSubject(false);
		this.initialData.forEach((value) => {
			this[value] = this.ls.getItem(value);
		});
	}

	/**
	* Login action
	* @param payload is credentials and detail of the user
	*/

	public register(payload: any): Observable<any> {
		const url: string = ApiRoutes.register;
		return this.http.post(url, payload, { observe: 'response' });
	}
	public otpregister(payload: any): Observable<any> {
		const url: string = ApiRoutes.otpregister;
		return this.http.get(`${url}?mobile=${payload.mobile}&countryCode=${payload.countryCode}&type=${payload.type}`, payload);
	}
	public registerPassword(payload: any): Observable<any> {
		const url: string = ApiRoutes.registerpassword;
		return this.http.post(url, payload, { observe: 'response' });
	}
	
	public checkUserStatus(payload: any): Observable<any> {
		const url: string = ApiRoutes.checkUserStatus;
		return this.http.post(url, payload, { observe: 'response' });
	}

	public verifyUser(token: any): Observable<any> {
		const url: string = ApiRoutes.verifyUser;
		const body = {
			verification_token : token
		};
		return this.http.post(url, body, { observe: 'response' });
	}

	public checkOtp(payload):Observable<any>{
		const url = ApiRoutes.verifyOtp; 
		return this.http.get(`${url}?user_id=${payload.user_id}&otp=${payload.otp}`, payload);
	}
	public resendOtp(payload):Observable<any>{
		const url = ApiRoutes.resendOtp; 
		return this.http.get(`${url}?user_id=${payload.user_id}`, payload);
	}

	/**
	* Login action
	* @param payload is credentials of the user
	*/
	public login(payload: LoginPayload): Observable<LoginPayload> {
		const url: string = ApiRoutes.login;
		return this.http.post<LoginPayload>(url, payload, { observe: 'response' }).pipe(map((data: any) => {
			console.log("login Response ",data);
			if(data.status == 200) {
				this.ls.setItem('token', data.body.user.token, payload.rememberMe);
				this.ls.setItem('currentUser', JSON.stringify(data.body.user), payload.rememberMe);
				// this.ds.shareData(data);
				this.token = data.token;
			}
			return data;
		}));
	}

	//* get decoded token */
	// public getToken(): string {
	// 	const helper = new JwtHelperService();
	// 	return helper.decodeToken(this.token);
	// }

	/**
	* Login action
	* @param token is JWT token
	*/
	public set token(token: string) {
		this.authToken = token;
		this.changeAuthState = !!token;
	}

	//* get auth token */
	public get token(): string {
		return this.authToken ? this.authToken : '';
	}

	/**
	* Change auth state of a user
	* @param newState of the user
	*/
	public set changeAuthState(newState: boolean) {
		this.authState.next(newState);
	}

	//** Method to check if user logged in */
	public isAuthenticated(): boolean {
		return !!this.token;
	}

	//** Logiut action */
	public logout(): void {
		this.ls.clear();
		this.token = null;
		this.changeAuthState = !!this.token;
		this.router.navigate([APP_ROUTES.login]);
  }

}
