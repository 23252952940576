import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CategoriesService } from '../../services/header.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class VerificationComponent implements OnInit {
  token:string;
  unsubscribe = new Subject();
  verificationSuccessFull:boolean = false;
  verificationunsuccessfull:boolean = false;
  errorMessage:string = '';

  constructor( private toastr: ToastrService,private categoriesService: CategoriesService,private route: ActivatedRoute, private titleService:Title,) { }

  ngOnInit(): void {
    this.titleService.setTitle('Confirm subscription | Social App Hub')
    this.route.queryParams.subscribe((params) => {
      if(params.token){
        this.token = params.token;
        this.verificationSubscription();
      }
    })
  }

  verificationSubscription(){
    const payload = {
      verification_token:this.token
    }
    this.categoriesService.subscriptionVerification(payload).pipe(takeUntil(this.unsubscribe)).subscribe((res)=>{
      this.verificationSuccessFull = true;
    },err =>{
      this.verificationunsuccessfull = true;
      this.errorMessage = err.error.message
    })

  }
  ngOnDestroy() {
    this.unsubscribe.unsubscribe();
  }


}
