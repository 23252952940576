// import { environment } from 'src/environments/environment';
import { KnowledgeSectionService } from './../preAuthPages/knowledge-section/knowledge-section.service';
import { APP_ROUTES } from './../../shared/routes/appRoutes';
import { LocalStorageService } from './../../shared/storage/local-storage.service';
import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Subject, combineLatest, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators'; ;
import { NgxSpinnerService } from "ngx-spinner";
import { DomSanitizer, Title } from '@angular/platform-browser';
import { environment } from "../../../environments/environment";
import { style, animate, trigger, transition, query } from '@angular/animations';
const listAnimation = trigger('listAnimation', [
    transition('* <=> *', [
      query(':enter',
        [
          style({ opacity: 0, transform: 'translateX(100px)' }),
          animate('1200ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'translateX(0)' }))
        ],
        { optional: true }
      ),
      query(':leave',
        animate('300ms', style({ opacity: 0, '-webkit-transform': 'translate3d(-100%, 0, 0)', transform: 'translate3d(-100%, 0, 0)' })),
        { optional: true}
      )
    ])
  ]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(100px)' }),
        animate('1200ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'translateX(0)' })),
      ]),
    ]),
    listAnimation
  ],

})
export class HomeComponent implements OnInit {

  private unsubscribe$ = new Subject();
  newsHtml : any;
  articlesHtml : any;
  interviewsHtml : any;
  caseStudyHtml : any;
  sliderImages : any;
  imageBasePath = environment.imgUrl;
  page = 1;

  itemsPerSlide = 3;
  itemsPerSlideFeatureApp = 1;
  cycleInterval = 2500;

  singleSlideOffset = true;
  noWrap = !true;
  approvedApplications = [];
  unsubscribeApprovedApps = new Subject();
  timerSubscription: Subscription;

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    arrows: true
    // responsive: [
    //   {
    //     settings: {
    //       arrows: true,
    //     }
    //   },

    // ]
    // infinite: true,
    // centerMode: false,
    // slidesToShow: 1,
    // slidesToScroll: 2
  }
  slideConfigArticles = {
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    dots: false,
    arrows: true,
    infinite: true,
    responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true
      }
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1

      }
    }
    // You can unslick at a given breakpoint now by adding:
    // settings: "unslick"
    // instead of a settings object
  ]
  }
  animateLeft = [
    style({ opacity: 0, transform: 'translateX(100px)' }),
    animate('1200ms cubic-bezier(0.35, 0, 0.25, 1)', style({ opacity: 1, transform: 'translateX(0)' })),
  ];

  animateBounce = [
    style({opacity: 0, transform: 'translate3d(0, -100%, 0)'}),
    animate('600ms cubic-bezier(0.35, 0, 0.25, 1)', style({opacity: 1, transform: 'translate3d(0, 0, 0)'}))
  ];

  constructor(
    private localStorage : LocalStorageService,
    private service : KnowledgeSectionService,
    private router: Router,
    private spinner: NgxSpinnerService,
     private sanitized: DomSanitizer,
     private titleService:Title
    ) { }

  ngOnInit(): void {
    this.titleService.setTitle("Welcome to Social App Hub");
    // this.localStorage.removeItem('password_state');
    this.initHomePage();
    this.getApprovedApps();
  }

  public signOut(){
    this.localStorage.clear();
    this.router.navigate([APP_ROUTES.login]);
}

initHomePage():void{
  this.spinner.show();
  const newsApi = this.service.getNews();
  const articlesApi = this.service.getArticles();
  const interviewsApi = this.service.getInterview();
  const caseStudyApi = this.service.getCaseStudy();
  const sliderApi = this.service.getHomeSliderImages();

  combineLatest(newsApi, articlesApi, interviewsApi, caseStudyApi,sliderApi)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([news, articles, interviews, caseStudy,slider]) => {
        this.newsHtml = news.body.newsdata;
        this.articlesHtml = articles.body.articles;
        this.interviewsHtml = interviews.body.interviews.slice(0,2);
        this.caseStudyHtml = caseStudy.body.casestudies.slice(0,3);
        this.sliderImages = slider.body.sliders;
        this.spinner.hide();
      });
}

transform(value) {
  if (value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

transformTitle(value) {
    if (value) {
      let data:any = value.replace(/<.*?>/g, '');
      return data.length <=80 ? data : data.substring(0,80) + '....'
      // return data.substring(0,80) + '....'
    }
  }

getApprovedApps(){
  this.service.getApprovedApps(this.page).pipe(takeUntil(this.unsubscribeApprovedApps)).subscribe((data)=>{
    if(data['status']){
        this.approvedApplications = data['applications'];
    }
  })

//   this.timerSubscription = timer(0, 10000).pipe(
//     switchMap(() => this.service.getApprovedApps())
//     ).subscribe((data)=>{
//         console.log("data", data)
//         if(data['status']){
            // this.approvedApplications = [];
            // setTimeout(() => {
            //     this.approvedApplications = data['applications'];
            // },500)

//         }
//     })

//   interval(1 * 5 * 1000)
//     .pipe(
//         flatMap(() => this.service.getApprovedApps())
//     ).subscribe((data)=>{
//         console.log("data", data)
//         if(data['status']){
//             this.approvedApplications = [];
//             setTimeout(() => {
//                 this.approvedApplications = data['applications'];
//             },500)

//         }
//     })
}

prevPage() {
    if(this.page > 1) {
        this.page -= 1;
        this.getApprovedApps();
    }
}

nextPage() {
    this.page += 1;
    this.getApprovedApps();
}

ngOnDestroy() {
  this.unsubscribe$.next();
  this.unsubscribe$.complete();
//   this.timerSubscription.unsubscribe();
  this.unsubscribeApprovedApps.unsubscribe();
}

detailOfApp(app){
  this.router.navigate(['/discover-app-detail'], { queryParams: {id: app['category'][0]['category_id']['_id'],appId:app._id} })
  // console.log(app,app['category'][0]['category_id']['_id']);

  // { id: this.activeCat , appId:id}
}
itemClickOnNgxClick(item){
  if(!item.url) return;
  switch(item.entity){
    // case 'application':{
    //   this.router.navigate(['/discover-app-detail'], { queryParams: { id: item['category'][0]['category_id'] , appId:item['url']} })
    //     break;
    // }
    case 'article':{
      this.router.navigate(['/knowledge-section/article/detail',item['url']])
      break;
    }
    case 'casestudy':{
      this.router.navigate(['/knowledge-section/case-study/detail', item['url']])
      break;
    }
    case 'interview':{
      this.router.navigate(['/knowledge-section/interview/detail',item['url']])
      break;
    }
    case 'other':{
      window.open(item['url'],'_blank')
      break;
    }
    default: {
      break;
    }
  }
  // this.router.navigateByUrl('/knowledge-section/case-study/detail/5f9b90d46e83d96ebc461676')
}
}
