<section class="pt-0 pb-0">
    <ngx-spinner></ngx-spinner>
















    <section class="casestudy-no-bg comman-page pt-0 overflow-visible mt-110 pb-280 ">

        <div class="container-fluid">
            <div class="nl-banner row">
                <div class="col-3 shape"></div>
                <div class="col newsletterContent">
                    <h3>News Letter</h3>
                </div>
            </div>

        </div>



        <div class="container">
            <div class="newsContent">
                <div class="row filter">

                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-12 col-lg-auto mb-2 mb-md-0 d-flex align-items-center">
                                <i class="fa fa-filter mr-2"></i> Filter
                            </div>
                            <div class="col-6 col-md-auto mb-2 mb-md-0">
                                <select class="form-control" (change)="selectFilter($event,'months')">
                                    <option [disabled]="true" value="">Select Newsletter Month</option>
                                    <option value="this_Month">This Month</option>
                                    <option value="last_six_month ">last six month</option>
                                    <option value="this_year">This Year</option>

                                </select>
                            </div>
                            <div class="col-6 col-md-auto mb-2 mb-md-0">
                                <select class="form-control" (change)="selectFilter($event,'category')">
                                    <option [disabled]="true" value="">Select Category</option>
                                    <option *ngFor="let item of categoryList" [value]="item?._id">
                                        {{ item?.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 d-flex justify-content-end align-items-center flex-wrap">
                        <div class="search"><input type="text" class="form-control" placeholder="Search" #searchVal (keydown.enter)="selectFilter(searchVal.value,'search')" /><i class="fa fa-search" (click)="selectFilter(searchVal.value,'search')"></i></div>
                        <span class="btn btn-primary ml-3">Total Newsletters : {{totalNewsLetters}}</span>
                    </div>





                </div>
                <div class="row pt-3">
                    <div class="col-md-4 mb-4" *ngFor="let data of newsLetters" animateOnScroll animationName="animated fadeIn" (click)="previewNewsLetter(data._id)">
                        <div class="emailThumb">
                            <div class="thumb">
                                <img [src]="imageBasePath + data?.thumbnail" alt="newsletter">
                            </div>
                            <div class="w-100 p-3">
                                <p class="mb-2">{{data?.subject}}</p>
                                <i>Release on : <span>{{data?.publishDate | date:'d MMM y,EEEE'}}</span></i>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="row pagination-row mb-0" [hidden]="!showPagination">
                    <div class="col-md-12 text-right my-3">
                        <div class="pagination justify-content-end">
                            <div class="page-item">
                                <pagination [boundaryLinks]="true" [itemsPerPage]="itemsPerPage" [totalItems]="totalNewsLetters" [(ngModel)]="page" (pageChanged)="pageChanged($event)" [customPreviousTemplate]="prevTemplate" [maxSize]="maxSize" [customNextTemplate]="nextTemplate" [customFirstTemplate]="firstTemplate"
                                    [customLastTemplate]="lastTemplate">
                                </pagination>
                                <ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
                                    <ng-container *ngIf="!disabled">
                                        Next
                                    </ng-container>
                                </ng-template>

                                <ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
                                    <ng-container *ngIf="!disabled">
                                        Previous
                                    </ng-container>
                                </ng-template>

                                <ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
                                    <ng-container *ngIf="!disabled">
                                        Last
                                    </ng-container>
                                </ng-template>

                                <ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
                                    <ng-container *ngIf="!disabled">
                                        First
                                    </ng-container>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="container d-none">
            <div class="inner-container">

                <!-- <div id="demo" class="carousel slide hero-slider ngo-month-slider casestudy-banner" data-ride="carousel" id="scrollingToTop">
                <div class="container">
                    <div class="bg-slider min-h-400">
                    <div class="carousel-inner">
                        <ngx-slick class="carousel" #slickModal="slick-modal" [config]="slideConfig">
                            <div ngxSlickItem *ngFor="let data of sliderData" class="slide">
                                <a href="{{data?.website}}" target="_blank">
                                <img [src]="imageBasePath + data.image" alt="first slide"
                                style="display: block; width: 100%;">
                            <div class="carousel-caption d-none d-md-block text-left">
                                <h3>{{data?.title}}</h3>
                            </div>
                        </a>
                            </div>

                        </ngx-slick>

                        <carousel [noPause]="false" [isAnimated]="true" [interval]="cycleInterval">
                            <slide *ngFor="let data of sliderData; let i=index">
                                <img [src]="imageBasePath + data.image" alt="first slide"
                                    style="display: block; width: 100%;">
                                <div class="carousel-caption d-none d-md-block text-left">
                                    <h3>{{data?.title}}</h3>
                                </div>
                            </slide>
                        </carousel>
                    </div>
                    </div>
                </div>
            </div> -->

                <!-- <p><strong><span class="roboto-light">By<strong>:</strong></span> {{data?.author}}</strong></p> -->

                <div class="ngo-lising" *ngFor="let data of newsLetters">
                    <div class="hide-on-init" animateOnScroll animationName="animated fadeIn" (click)="previewNewsLetter(data._id)">
                        <div class="row  mb-3 align-items-center ng-top">
                            <div class="col-md-6 cs-left">
                            </div>
                        </div>

                        <div class="row ngm-content">
                            <div class="col-md-2 ngmc-left">
                                <div class="ng-img">
                                    <!-- <img [src]="imageBasePath + data.type.images[0]" alt="" class="w-100"> -->
                                    <div class="ngo-logo"><img [src]="imageBasePath + data?.thumbnail" alt=""></div>
                                </div>
                            </div>
                            <div class="col-md-10 ngmc-right">
                                <div class="share-div right-15">
                                    <!-- <p class="text-left">Share this on</p> -->
                                    <!-- <share-buttons [theme]="'circles-dark'"
                                [include]="['facebook','twitter','linkedin']"
                                [show]="3"
                                [size]="-6"
                                [url]='data?.website'
                                [autoSetMeta]="false"
                                [title]="data?.name"
                                [description]="data?.name"
                               ></share-buttons> -->
                                    <!-- <ul class="share-now d-flex">
                                    <li><a href="https://www.facebook.com/socialapphub" target="_blank"><img src="assets/img/case-study/share-icon3.png" alt=""></a></li>
                                    <li><a href="https://in.linkedin.com/" target="_blank"><img src="assets/img/case-study/share-icon5.png" alt=""></a></li>
                                    <li><a href="https://twitter.com/SocialAppHub" target="_blank"><img src="assets/img/case-study/share-icon6.png" alt=""></a></li>
                                    <li><a href="https://instagram.com/socialapphub?igshid=9foj2xxzn44z" target="_blank"><img src="assets/img/ins-share.png" alt=""></a></li>
                                </ul> -->
                                </div>
                                <h4 class="mb-0 pr-350">{{data?.subject}}</h4>
                                <h2 class="page-title pl-0 pt-0"><i class="fa fa-calendar" aria-hidden="true"></i>{{data?.month}}</h2>
                                <!-- <div class="link mt-2 mb-2 pr-350"><a target="_blank" [href]="data.website">{{data?.website}}</a></div> -->

                                <!-- <div class="ng-description">
                                <p>
                                    <span [innerHTML]="transform((data.description.length > 100) ? (data.description | slice:0:100 | safeHtml) + '...' : data.description)">
                                    </span></p>
                                    <a *ngIf="data.onModel==='CaseStudy'"
                                        [routerLink]="['/knowledge-section/case-study/detail',data?.type?._id]">read more</a>
                                <a *ngIf="data.onModel==='Interviews'"
                                    [routerLink]="['/knowledge-section/interview/detail',data?.type?._id]">read more</a>
                                <a *ngIf="data.onModel==='Articles'"
                                    [routerLink]="['/knowledge-section/article/detail',data?.type?._id]">>read more</a>
                            </div> -->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row pagination-row mt-5 mb-0" [hidden]="!showPagination">
                    <div class="col-md-12 text-right">
                        <div class="pagination justify-content-end">
                            <div class="page-item">
                                <pagination [boundaryLinks]="true" [itemsPerPage]="itemsPerPage" [totalItems]="totalNewsLetters" [(ngModel)]="page" (pageChanged)="pageChanged($event)" [customPreviousTemplate]="prevTemplate" [maxSize]="maxSize" [customNextTemplate]="nextTemplate" [customFirstTemplate]="firstTemplate"
                                    [customLastTemplate]="lastTemplate">
                                </pagination>
                                <ng-template #nextTemplate let-disabled="disabled" let-currentPage="currentPage">
                                    <ng-container *ngIf="!disabled">
                                        Next
                                    </ng-container>
                                </ng-template>

                                <ng-template #prevTemplate let-disabled="disabled" let-currentPage="currentPage">
                                    <ng-container *ngIf="!disabled">
                                        Previous
                                    </ng-container>
                                </ng-template>

                                <ng-template #lastTemplate let-disabled="disabled" let-currentPage="currentPage">
                                    <ng-container *ngIf="!disabled">
                                        Last
                                    </ng-container>
                                </ng-template>

                                <ng-template #firstTemplate let-disabled="disabled" let-currentPage="currentPage">
                                    <ng-container *ngIf="!disabled">
                                        First
                                    </ng-container>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</section>