<section class="pt-0 pb-0">
    <ngx-spinner></ngx-spinner>

    <section class="casestudy-no-bg comman-page ngo-month-page pt-0 overflow-visible mt-110 pb-280 ">
        <div class="container newletterDetail">
            <div class="row">
                <div class="col-md-8 boxShadow1 py-3">
                    <div *ngFor="let item of newsLetterPreview.data">
                        <a href="{{item?.url}}" target="_blank"><img src="{{imagePath}}{{item?.image}}"></a>

                    </div>
                </div>
                <div class="col-md-4">
                    <div class="newletterDetailInfo">
                        <p><strong>Subject:</strong> {{newsLetterPreview?.subject}} </p>
                        <p> <strong>Release date:</strong> {{newsLetterPreview?.month}}/{{newsLetterPreview?.year}}</p>
                        <p class="pb-0 mb-0"><strong>Category:</strong> {{newsLetterPreview?.category.name}}</p>
                    </div>
                </div>
            </div>

        </div>
    </section>

</section>