import { PreAuthService } from './../pre-auth.service';
import { ApiRoutes } from './../../../shared/routes/apiRoutes';
import { APP_ROUTES } from './../../../shared/routes/appRoutes';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators,FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  public APP_ROUTES = APP_ROUTES;
	public ChangePasswordForm: FormGroup;
	public isSubmitting: boolean = false;
	public isDisabled: boolean = false;
	public formSubmitSuccess: boolean = false;
	resetToken : any;
	constructor(private router: Router,
		 private service: PreAuthService,
		  private route: ActivatedRoute,
		  private toastr: ToastrService,
		  private fb :FormBuilder,
		  private titleService:Title) { }

	ngOnInit(): void {
		this.titleService.setTitle('Change Password | Social App Hub');
		this.resetToken = this.route.snapshot.queryParamMap.get('token');
		this.checkResetToken(this.resetToken);
		this.initForm();
	}

	checkResetToken(resetToken : any){

		this.service.checkResetToken(resetToken).subscribe((response: any) => {
			console.log(" response of checkResetToken api ", response)
			if (response.status === 200) {
				console.log("to changePassword");
			}
		}, err => {
			console.log("to login");
			this.toastr.error(err.error.message, '');
			this.router.navigate([APP_ROUTES.login]).then(() => {
				// for some async task
			});
		});

	}

	private initForm(): void {
		this.ChangePasswordForm = this.fb.group({
			newPassword: ['', [Validators.required, Validators.minLength(6),Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)]],
			confirmNewPassword: ['', [Validators.required, Validators.minLength(6),this.passwordMatcher.bind(this),Validators.pattern(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/)]]
		});
	}

	private passwordMatcher(control: FormControl): { [s: string]: boolean } {
        if (this.ChangePasswordForm && (control.value !== this.ChangePasswordForm.controls.newPassword.value)) {
            return { passwordNotMatch: true };
		}
        return null;
    }


	change(e: number): void {
		console.log(this.ChangePasswordForm.value.confirmNewPassword,this.ChangePasswordForm.value.newPassword,">>>>>>>>>>>",this.ChangePasswordForm);
		
		this.formSubmitSuccess = false;
		if (this.ChangePasswordForm.invalid) {
			this.isSubmitting = true;
			return;
		}
		this.isSubmitting = false;
		let payload = {
			token : this.resetToken,
			password : this.ChangePasswordForm.value.newPassword
		}
		this.service.changePassword(payload).subscribe((data: any) => {
			if (data.status === 200) {
				this.formSubmitSuccess = true;
				this.ChangePasswordForm.reset();
				setTimeout(() => {
					this.toastr.success(data.body.message, '');
				}, 1000);
				this.router.navigate([APP_ROUTES.login]).then(() => {
				});
			}
		}, err => {
			this.toastr.error(err.error.message, '');
		});
	}

}
