import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LoggedInUserGuard implements CanActivate {

  constructor(private router: Router,private location: Location) {}


canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (localStorage.getItem('token') || sessionStorage.getItem('token')) {
      this.router.navigate(["/profile"]); // or home
      return false;
    }
    return true;
  }

  goBack() {
    this.location.back();
  }
}
