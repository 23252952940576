// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // apiBaseUrl : 'https://api.socialapphub.com/',
  // production: false,
  // facebookProviderId : 772226203631149,
  // googleProviderId:'360369745521-nt6ioj3p13kt3h7ucuq88ou0ub178a1e.apps.googleusercontent.com',
  // imgUrl : 'https://apisocialhub.signitydemo.in/uploads/images/',
  // itemsPerPage : 1,
  // sharingUrl: 'https://sah.signitydemo.in/share'
  apiBaseUrl : 'https://api.socialapphub.com/',
  production: true,
  // facebookProviderId : 804566680119675,
  facebookProviderId : 1058839274919625,
  googleProviderId : '853285244661-qje4btjv19n8i1p9rusanurknvm1hrke.apps.googleusercontent.com',
  imgUrl : 'https://api.socialapphub.com/uploads/images/',
  itemsPerPage : 10,
  sharingUrl: 'https://www.socialapphub.com/share'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
