import { APP_ROUTES } from 'src/app/shared/routes/appRoutes';
import { LocalStorageService } from './../../storage/local-storage.service';
import {
    Component,
    OnInit,
    ElementRef,
    OnDestroy,
    ViewChild,
    HostListener,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CategoriesService } from '../../services/header.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { Subscription, interval, Subject } from 'rxjs';
import { flatMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    @ViewChild('mobileMenu') mobileMenu: ElementRef<HTMLElement>;
    showSignUp = false;
    showSignOut = false;
    loginOrRegister: boolean = false;
    catList: any = [];
    isMobile = false;
    isDesktop = false;
    activeSubmenu: string;
    searchText: string = '';
    showSearchBar: boolean = false;
    staticpages: any;
    notificationCount: number = 0;
    notificationList: any;
    intervalObs: Subscription;
    unsubscribeNotifications = new Subject();
    imageUrl = environment.imgUrl
    constructor(
        private localStorage: LocalStorageService,
        private router: Router,
        private elementRef: ElementRef,
        private categoriesService: CategoriesService,
        private deviceService: DeviceDetectorService,
        private toastr: ToastrService
    ) { }

    ngOnInit(): void {
        this.getAllCat();
        this.getAllStaticPages();
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.checkIfUserLoggedIn();
                this.showSearchBar = false;
            }
        });
    }

    getNotifications() {
        if (this.localStorage.getItem('currentUser')) {
            let userId = this.localStorage.getItem('currentUser')._id;
            this.categoriesService.getNotifications(userId).subscribe((res) => {
                if (res['status']) {
                    this.notificationCount = res['unread_count'];
                    this.notificationList = res['notifications'];
                }
            }, err => {

            })
        }
    }

    callingInterval() {
        let userId = this.localStorage.getItem('currentUser')._id;
        this.intervalObs = interval(60 * 1000)
            .pipe(flatMap(() =>
                    this.categoriesService.getNotifications(userId)
                        .pipe(takeUntil(this.unsubscribeNotifications))
                ))
            .subscribe((res) => {
                if (res['status']) {
                    this.notificationCount = res['unread_count'];
                    this.notificationList = res['notifications'];
                }
            });
    }
    notificationEvent(index, type, id) {
        if (type == 'read') {
            this.notificationList.map((item, index) => {
                if (item._id == id) {
                    this.notificationList[index]['is_read'] = true;
                }
            })
            this.categoriesService.readSingleNotification(id).subscribe((data) => {
                if (data['status']) {
                    this.getNotifications();
                }
            }, err => {
                this.toastr.error(err.error.message)
            });
        }
        if (type == 'delete') {
            this.categoriesService.deleteNotification(id).subscribe((data) => {
                if (data['status']) {
                    this.getNotifications();
                    this.toastr.success(data['message'])
                }
            }, err => {
                this.toastr.error(err.error.message)
            }
            );
        }
    }
    readAllNotifications() {
        let userId: any;
        if (this.localStorage.getItem('currentUser')) {
            userId = this.localStorage.getItem('currentUser')._id;
        }
        this.categoriesService.readAllNotifications(userId).subscribe(res => {
            if (res['status']) {
                this.notificationCount = 0;
                this.notificationList.map(_m => {
                    _m.is_read = true;
                })
                this.getNotifications();
            }
        }, err => {
        }
        )

    }
    transform(value) {
        if (value.length > 25) {
            let data: any = value.replace(/<.*?>/g, '');
            return data.substring(0, 25) + '....'
        }
        else return value
    }

    @HostListener('document:click', ['$event'])
    clickout(event) {
        if (this.elementRef.nativeElement.contains(event.target)) {
        } else {
            this.showSearchBar = false;
        }
    }
    getAllStaticPages() {
        this.categoriesService.getAllStaticPages({}).subscribe((res) => {
            if (res['status']) {
                const data = res['pages'];
                this.staticpages = data.filter((data) => data.state == 'dynamic');
            }
        }, (err) => {
            if (err.error.message) {
            }
        });
    }

    getAllCat() {
        this.categoriesService.getAll({}).subscribe((res) => {
            if (res['status']) {
                this.catList = res['categories'];
            }
        });
    }
    checkIfUserLoggedIn(): void {
        //!this.localStorage.getItemString('password_state')
        if (this.localStorage.getItemString('token')) {
            this.getNotifications();
            this.callingInterval();
            this.showSignUp = false;
            this.showSignOut = true;
        } else {
            this.showSignUp = true;
            this.showSignOut = false;
        }
    }

    public signOut() {
        this.localStorage.clear();
        this.categoriesService.setnewletterSuscription(false);
        this.router.navigate([APP_ROUTES.login]);
    }

    public signUp() {
        this.loginOrRegister = !this.loginOrRegister;
        this.router.navigate([APP_ROUTES.login]);
    }
    public signIn() {
        this.loginOrRegister = !this.loginOrRegister;
        this.router.navigate([APP_ROUTES.login]);
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.isMobile = this.deviceService.isMobile();
            this.isDesktop = this.deviceService.isDesktop();
        });
        this.elementRef.nativeElement
            .querySelector('#hamburgerBtn')
            .addEventListener('click', this.handleHamburger);
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                (<any>window).gtag('set', 'page', event.urlAfterRedirects);
                (<any>window).gtag('send', 'pageview');
                (<any>window).fbq('track', 'PageView');
            }
        });
    }

    handleHamburger = (event) => {
        const classList = this.mobileMenu.nativeElement.classList;
        if (classList.contains('mobile-menu-open')) {
            classList.remove('mobile-menu-open');
        } else {
            classList.add('mobile-menu-open');
        }
        if (classList.contains('d-none')) {
            classList.remove('d-none');
        } else {
            classList.add('d-none');
        }
        this.activeSubmenu = '';
    };

    closeNavBar($event) {
        $event.stopPropagation();
        const classList = this.mobileMenu.nativeElement.classList;
        if (classList.contains('mobile-menu-open')) {
            classList.remove('mobile-menu-open');
        }
        if (!classList.contains('d-none')) {
            classList.add('d-none');
        }
        this.activeSubmenu = '';
    }

    handleSubmenu(menu) {
        if (this.activeSubmenu == menu) {
            this.activeSubmenu = '';
        } else {
            this.activeSubmenu = menu;
        }
    }

    ngOnDestroy(): void {
        this.elementRef.nativeElement
            .querySelector('#hamburgerBtn')
            .removeEventListener('click', this.handleHamburger);
        this.intervalObs.unsubscribe();
        this.unsubscribeNotifications.unsubscribe();
    }
    // linkClick(event,abc){
    //   event.preventDefault();

    //   this.router.navigate([abc]);
    // }

    searchEntity() {
        if (this.searchText == ' ') return;
        if (this.searchText == '') {
            this.showSearchBar = !this.showSearchBar;
        }
        if (this.searchText) {
            this.showSearchBar = false;
            this.router.navigate(['/search'], {
                queryParams: { query: this.searchText },
            });
            this.searchText = '';
        }
        // this.categoriesService.getSearchData(this.searchText).subscribe(res => {
        //   if(res['status']) {

        //   }
        // })
    }
}
