<section class="form-pages progile-page-row pt-120 bg-center">

    <div class="container">
        <div class="row align-items-center main-row">

            <div class="col-md-12 form-right-content progile-page-col">
                <h2 class="font26 form-color1 mb-4 font-bold">NEWSLETTER SUBSCRIPTION

                </h2>
                <div class="form-wrap">

                    <form novalidate [formGroup]="newsLetterForm">
                        <div class="row personal-info">
                            <div class="col-md-6 pi-col">
                                <div class="form-group">
                                    <span class="outer-label">Email</span>
                                    <input type="text" name="" class="form-control" placeholder="email"
                                        formControlName="email" autocomplete="off">
                                </div>
                                <ng-container
                                    *ngIf="newsLetterForm.controls['email'].errors && (newsLetterForm.get('email').dirty || newsLetterForm.get('email').touched)">
                                    <small class="text-danger error-msg"
                                        [hidden]="!newsLetterForm.controls['email'].errors.required">*email is
                                        required.</small>
                                </ng-container>
                            </div>
                            <div class="col-md-6 pi-col">
                                <div class="form-group">
                                    <span class="outer-label">PhoneNo</span>
                                    <input type="text" name="" class="form-control" placeholder="Phone No"
                                        formControlName="phoneNo" autocomplete="off">
                                </div>
                                <ng-container
                                    *ngIf="newsLetterForm.controls['phoneNo'].errors && (newsLetterForm.get('phoneNo').dirty || newsLetterForm.get('phoneNo').touched )">
                                    <small class="text-danger error-msg"
                                        [hidden]="!newsLetterForm.controls['phoneNo'].errors.required">*Phone No is
                                        required.</small>
                                </ng-container>
                            </div>

                            <div class="col-md-6 pi-col">
                                <div class="form-group">
                                    <span class="outer-label">Company</span>
                                    <input type="text" name="" class="form-control" placeholder="company"
                                        formControlName="company" autocomplete="off">
                                </div>
                                <ng-container
                                    *ngIf="newsLetterForm.controls['company'].errors && (newsLetterForm.get('company').dirty || newsLetterForm.get('company').touched )">
                                    <small class="text-danger error-msg"
                                        [hidden]="!newsLetterForm.controls['company'].errors.required">*Phone No is
                                        required.</small>
                                </ng-container>
                            </div>



                            <div class="col-md-6 pi-col select-col">
                                <div class="form-group">
                                    <span class="outer-label">Designation</span>
                                    <div class="select-div">
                                        <select class="form-control" id="sel1" (change)="changeSelection($event,'designation')"
                                            formControlName="designation">
                                            <option value="">Choose Designation</option>
                                            <option *ngFor="let designation of Designations" [ngValue]="designation">
                                                {{designation}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 pi-col select-col">
                                <div class="form-group">
                                    <span class="outer-label">Role</span>
                                    <div class="select-div">
                                        <select class="form-control" id="sel1" (change)="changeSelection($event,'role')"
                                            formControlName="role">
                                            <option value="">Choose Role</option>
                                            <option *ngFor="let role of Roles" [ngValue]="role">
                                                {{role}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 pi-col">
                                <div class="form-group">
                                    <span class="outer-label">First Name</span>
                                    <input type="text" name="" class="form-control" placeholder="firstName"
                                        formControlName="firstName" autocomplete="off">
                                </div>
                                <ng-container
                                    *ngIf="newsLetterForm.controls['firstName'].errors && (newsLetterForm.get('firstName').dirty || newsLetterForm.get('firstName').touched )">
                                    <small class="text-danger error-msg"
                                        [hidden]="!newsLetterForm.controls['firstName'].errors.required">*Phone No is
                                        required.</small>
                                </ng-container>
                            </div>

                        </div><!-- personal-info end -->

                        <div class="row action-btn mt-4">
                            <div class="col-md-12 act-btn-left text-center">
                                <div class="form-group">
                                    <button type="submit" class="btn btn-primary rounded-pill"
                                        >Save
                                        Changes</button>
                                </div>
                            </div>
                        </div><!-- action-btn end -->


                    </form>
                </div>
            </div>

        </div>
    </div>

</section><!-- End Hero -->