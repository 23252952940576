<section class="form-pages pt-120 pb-280 logins-comman">

    <div class="container">
        <div class="row align-items-center main-row">
            <div class="col-md-7 form-left-content">
                <h2 class="font-bold font42">Thank you for deciding <br> to create a <span class="text-red">Social App Hub</span> <br> Account.
                </h2>
                <p class="font-20">You are warmly welcomed to our community. We invite you to follow our social media feeds in addition to regularly visiting this website to stay informed about the social good sector:
                </p>
            </div>
            <div class="col-md-5 form-right-content">

                <div class="form-wrap">
                    <form class="signin-form" novalidate [formGroup]="forgotForm">
                        <div class="form-heading">
                            <h3 class="font30 form-color1">Forgot Password !</h3>
                            <p class="form-color2">Please enter your Email / Mobile Number</p>
                        </div>

                        <div class="row basic-info">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <span class="outer-label">Mobile</span>
                                    <input type="text" name="" class="form-control" placeholder="Enter Email / Mobile Number" [ngClass]="((forgotForm.controls['email'].errors?.required) && isSubmitting)? 'error-msg':''" formControlName="email"
                                        autocomplete="off">
                                </div>
                                <!-- || forgotForm.controls['email'].errors?.pattern -->
                                <!-- <ng-container
									*ngIf="forgotForm.controls['email'].errors && (forgotForm.get('email').dirty || forgotForm.get('email').touched || isSubmitting)">
									<small class="text-danger error-msg"
										[hidden]="!forgotForm.controls['email'].errors.required">*Email is
										required.</small>
									<small class="text-danger error-msg"
										[hidden]="!forgotForm.controls['email'].errors.pattern">*Email is
										invalid.</small>
                                </ng-container> -->
                                <ng-container *ngIf="showError">
                                <small class="text-danger error-msg">* Enter Valid Number Or Email</small>
                            </ng-container>
                            </div>
                        </div>
                        <!-- basic-info end -->

                        <div class="row basic-info2" [hidden]="!step1registrationstarted">
                            <div class="form-wrap animated slow fadeIn">
                                <div class="form-heading">
                                    <h3 class="font30 form-color1">OTP Verification</h3>
                                </div>
                                <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="otpConfig">
                                </ng-otp-input>
                                <div class="row justify-content-center action-btn">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <span  [hidden]="displayResendOtpButton">Resend Otp in 
                                            <countdown #countdown [config]="countDownConfig" (event)="handleEvent($event)"></countdown></span>
                                            <button type="submit" [hidden]="!displayResendOtpButton" (click)="resendOtp()"
                                                class="btn btn-primary rounded-pill w-100">Resend OTP</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div  [hidden]="!step1registrationCompleted">
                            <P>Number Verified</P>
                        </div>
                        <div class="row basic-info2" [hidden]="!step1registrationCompleted">
                            <div class="col-md-12">
                                <div class="form-group password-icon">
                                    <span class="outer-label">Password</span>
                                    <input (keydown.space)="$event.preventDefault();" placeholder="Password" type="{{passwordVisibility ? 'text' : 'password'}}" name="password" [ngClass]="((forgotForm.controls['password'].errors?.required || forgotForm.controls['password'].errors?.minlength || forgotForm.controls['password'].errors?.pattern) && isSubmitting)? 'error-msg':''"
                                        class="form-control pr-5" formControlName="password" autocomplete="off">
                                    <a href="javascript:;" class="icon"><img src="assets/img/eye.png" alt="view" (click)="passwordVisibility = !passwordVisibility" /></a>
                                </div>
                                <p class="note-quote"><i class="fa fa-info-circle" aria-hidden="true"></i>Password should contains a lowercase, a uppercase character and a digit.

                                </p>
                            </div>
                        </div>

                        <div class="row action-btn">
                            <div class="col-md-6 act-btn-left">
                                <div class="form-group">
                                    <button type="submit" [hidden]="step1registrationCompleted" (click)="doForgot($event)"
                                        class="btn btn-primary rounded-pill w-100">Continue</button>
                                    <button type="submit" [hidden]="!step1registrationCompleted" (click)="doForgotSubmission($event)"
                                        class="btn btn-primary rounded-pill w-100">Submit</button>
                                    <!-- <button type="submit" class="btn btn-primary rounded-pill w-100" (click)="doForgot($event)">Submit</button> -->
                                </div>
                            </div>
                            <div class="col-md-6 act-btn-right">
                                <div class="form-group">
                                    <button type="submit" class="btn rounded-pill btn-default w-100 grey-border" [routerLink]="['../' + APP_ROUTES.login]">Login</button>
                                </div>
                            </div>
                        </div>
                        <!-- action-btn end -->
                        <p *ngIf="isForgotSuccess" class="sent-message">Please follow instructions as sent on your email
                        </p>
                    </form>
                </div>
            </div>

        </div>
    </div>

</section>
<!-- End Hero -->