import { ApiRoutes } from './../../shared/routes/apiRoutes';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class PreAuthService {

  constructor(private http: HttpClient) { }

  public changePassword(payload: any): Observable<any> {
		const url: string = ApiRoutes.changePassword;
		return this.http.post(url, payload, { observe: 'response' });
	}

	public setSocialPassword(payload: any): Observable<any> {
		const url: string = ApiRoutes.setPassword;
		return this.http.post(url, payload, { observe: 'response' });
	}

	public forgotPassword(payload: any): Observable<any> {
		const url: string = ApiRoutes.forgotPassword;
		return this.http.post(url, payload, { observe: 'response' });
	}

	checkResetToken(resetToken): Observable<any>{
		const url: string = ApiRoutes.checkResetToken;
		return this.http.post(url, {token : resetToken}, { observe: 'response' });
	}

	public getQueryParams(url: string, data: any) {
		let queryString: string = '';
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				if (!queryString) {
					queryString = `?${key}=${data[key]}`;
				} else {
					queryString += `&${key}=${data[key]}`;
				}
			}
		}
		return url + queryString;
	}
}
